import HeaderBar from "../HeaderMenu/headerMenu";
import LayoutProps from "./LayoutProps";
import "./scss/layout.scss";

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div>
      {/* Header Bar */}
      <HeaderBar />

      {/* Main content area */}
      <main className="main-layout">{children}</main>

      {/* Customize the footer */}
      <footer />
    </div>
  );
};

export default Layout;
