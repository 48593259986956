import { useEffect, useRef, useState } from "react";
import { SelectionState } from "./types/SelectionStates";
import SelectionBtn from "./components/SelectionBtn/SelectionBtn";
import { Board, Piece, PieceType } from "./types/Board";
import { Button, TextField, Box, Modal, Typography, Select, MenuItem } from "@mui/material";
import { emptyBoardFEN, initialFEN } from "./constants";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/types";
import { boardThemes } from "../../constants";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import { numberToChar } from "../../utils/numberToChar";
import { toSvg, toPng, toJpeg } from "html-to-image";
import GetAppIcon from "@mui/icons-material/GetApp";
import DrawingInfoDisplayer from "../../components/DrawingInfoDisplayer/DrawingInfoDisplayer";
import ChessArrow from "../../components/ChessArrow/ChessArrow";
import "./scss/editor.scss";
const validateFEN = require("fen-validator").default;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 20,
};

const EditorWithDynamicSize = () => {
  const { pieceSet, backgroundTheme, analysisPreferences } = useSelector((state: RootState) => state.board);

  const localPreferences = analysisPreferences ?? {
    primaryColor: "#ff0000",
    secondaryColor: "#00ff00",
    tertiaryColor: "#ffff00",
    dotRange: 50,
  };

  const containerDiv = useRef<HTMLDivElement>(null);
  const boardAreaRef = useRef<HTMLDivElement>(null);

  const [currentSelectionState, setSelectionState] = useState<SelectionState>(SelectionState.FreeSelection);

  const [moveList, setMoveList] = useState<any[]>([]);

  const [mode, setMode] = useState<"editor" | "game">("editor");
  const toggleMode = () => setMode((prevValue) => (prevValue === "editor" ? "game" : "editor"));

  const [currentFEN, setCurrentFEN] = useState<string>();
  const [board, setBoard] = useState<Board>({
    pieces: [],
    playingOrder: "w",

    castlingOptions: {
      whiteKingSide: false,
      whiteQueenSide: false,
      blackKingSide: false,
      blackQueenSide: false,
    },
  });

  const [selectedGame, setSelectedGame] = useState<string>("-1");
  const isFENSetted = useRef<boolean>(false);

  const lastMove = useRef<string>("");

  const isTurnedBack = useRef<boolean>(false);

  const movementContainerRef = useRef<HTMLDivElement | null>(null);

  const [isOptionKeyDown, setIsOptionKeyDown] = useState<boolean>(false);
  const [isShiftKeyDown, setIsShiftKeyDown] = useState<boolean>(false);
  const [isGKeyDown, setIsGKeyDown] = useState<boolean>(false);
  const [isRKeyDown, setIsRKeyDown] = useState<boolean>(false);
  const [isYKeyDown, setIsYKeyDown] = useState<boolean>(false);

  const [primarySelectedTiles, setPrimarySelectedTiles] = useState<string[]>([]);
  const [primaryDottedTiles, setPrimaryDottedTiles] = useState<string[]>([]);
  const [primaryArrowStart, setPrimaryArrowStart] = useState<string>("");
  const [primaryArrows, setPrimaryArrows] = useState<string[]>([]);

  const [secondarySelectedTiles, setSecondarySelectedTiles] = useState<string[]>([]);
  const [secondaryDottedTiles, setSecondaryDottedTiles] = useState<string[]>([]);
  const [secondaryArrowStart, setSecondaryArrowStart] = useState<string>("");
  const [secondaryArrows, setSecondaryArrows] = useState<string[]>([]);

  const [tertiarySelectedTiles, setTertiarySelectedTiles] = useState<string[]>([]);
  const [tertiaryDottedTiles, setTertiaryDottedTiles] = useState<string[]>([]);
  const [tertiaryArrowStart, setTertiaryArrowStart] = useState<string>("");
  const [tertiaryArrows, setTertiaryArrows] = useState<string[]>([]);

  const [isExportModalOpen, setExportModalOpen] = useState<boolean>(false);

  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [renderHelper, setRenderHelper] = useState<boolean>(false);

  const handleResize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClose = () => {
    setExportModalOpen(false);
  };

  const [colCount, setColCount] = useState<number>(4);
  const [rowCount, setRowCount] = useState<number>(4);

  const handleKeyDown = (e: any) => {
    console.log("keyDown", e.key);

    if (mode === "game") {
      return;
    }

    if (e.key === "Backspace" || e.key === "Delete") {
      clearDrawings();
    }

    if (e.key === "s") {
      setIsGKeyDown(true);
    }

    if (e.key === "p") {
      setIsRKeyDown(true);
    }

    if (e.key === "t") {
      setIsYKeyDown(true);
    }

    if (e.key === "Alt") {
      setIsOptionKeyDown(true);
    }

    if (e.key === "Shift") {
      setIsShiftKeyDown(true);
    }
  };

  const handleKeyUp = (e: any) => {
    // Reset the state when the option key is released
    if (e.key === "s") {
      setIsGKeyDown(false);
    }

    if (e.key === "p") {
      setIsRKeyDown(false);
    }

    if (e.key === "t") {
      setIsYKeyDown(false);
    }

    if (e.key === "Alt") {
      setIsOptionKeyDown(false);
    }
    if (e.key === "Shift") {
      setIsShiftKeyDown(false);
    }
  };

  useEffect(() => {
    containerDiv.current?.focus();

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
  }, []);

  useEffect(() => {
    if (validateFEN(currentFEN)) {
      setBoard(FENToBoard(currentFEN ?? ""));
      isFENSetted.current = true;
    }
  }, [currentFEN]);

  useEffect(() => {
    if (!isFENSetted.current) {
      setCurrentFEN(boardToFEN());
    }
    isFENSetted.current = false;
  }, [board]);

  useEffect(() => {
    if (selectedGame.length > 0 && selectedGame !== "-1") {
      setCurrentFEN(selectedGame);
    }
  }, [selectedGame]);

  useEffect(() => {
    if (mode === "game" && isTurnedBack.current !== true) {
      setMoveList((prevValue) => {
        return [...prevValue, { move: lastMove.current, FEN: currentFEN }];
      });
    }
  }, [currentFEN]);

  useEffect(() => {
    scrollBottomInMovementList();
  }, [moveList]);

  const handleExport = (exportType: "jpg" | "svg") => {
    if (!boardAreaRef.current) return;

    let exporter = exportType === "svg" ? toSvg : toJpeg;

    let exportOptions: any = {};
    if (exportType === "jpg") {
      exportOptions.quality = 1;
    }

    // Temporarily adjust styles for export
    const originalStyle = boardAreaRef.current.style.cssText;
    boardAreaRef.current.style.width = "3200px";
    boardAreaRef.current.style.height = "3200px";

    setRenderHelper((prevVal) => !prevVal);
    setTimeout(() => {
      if (!boardAreaRef.current) return;

      exporter(boardAreaRef.current, exportOptions)
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `chess-board.${exportType}`;
          link.href = dataUrl;
          link.click();

          if (!boardAreaRef.current) return;
          // Revert to original styles after export
          boardAreaRef.current.style.cssText = originalStyle;
          setExportModalOpen(false);
        })
        .catch((err) => {
          console.log(err);

          if (!boardAreaRef.current) return;
          // Revert to original styles in case of error
          boardAreaRef.current.style.cssText = originalStyle;
        });
    }, 100);
  };

  const FENToBoard = (fen: string): Board => {
    const fenParts = fen.split(" ");

    if (fenParts.length !== 6) {
      throw new Error("Invalid FEN string");
    }

    const pieceTypeFromFEN: { [key: string]: PieceType } = {
      k: PieceType.BKing,
      q: PieceType.BQueen,
      r: PieceType.BRook,
      b: PieceType.BBishop,
      n: PieceType.BKnight,
      p: PieceType.BPawn,
      K: PieceType.WKing,
      Q: PieceType.WQueen,
      R: PieceType.WRook,
      B: PieceType.WBishop,
      N: PieceType.WKnight,
      P: PieceType.WPawn,
    };

    const [piecePlacement, turn, castling, enPassant, halfMove, fullMove] = fenParts;

    const pieces: Piece[] = [];
    let row = 0;
    let col = 0;

    for (const char of piecePlacement) {
      if (char === "/") {
        row++;
        col = 0;
      } else if (char.match(/\d/)) {
        col += parseInt(char, 10);
      } else {
        const pieceType = pieceTypeFromFEN[char];
        if (pieceType) {
          pieces.push({ x: row, y: col, type: pieceType });
        }
        col++;
      }
    }

    const playingOrder = turn === "w" ? "w" : "b";

    // Parse castling options
    const castlingOptions = {
      whiteKingSide: castling.includes("K"),
      whiteQueenSide: castling.includes("Q"),
      blackKingSide: castling.includes("k"),
      blackQueenSide: castling.includes("q"),
    };

    return {
      pieces,
      playingOrder,
      castlingOptions,
    };
  };

  const boardToFEN = (): string => {
    const pieceTypeToFEN: { [key in PieceType]: string } = {
      blackKing: "k",
      blackQueen: "q",
      blackRook: "r",
      blackBishop: "b",
      blackKnight: "n",
      blackPawn: "p",
      whiteKing: "K",
      whiteQueen: "Q",
      whiteRook: "R",
      whiteBishop: "B",
      whiteKnight: "N",
      whitePawn: "P",
    };

    let fen = "";

    for (let row = 0; row < 8; row++) {
      let emptySquares = 0;

      for (let col = 0; col < 8; col++) {
        const piece = board.pieces.find((p) => p.x === row && p.y === col);

        if (piece) {
          if (emptySquares > 0) {
            fen += emptySquares.toString();
            emptySquares = 0;
          }

          fen += pieceTypeToFEN[piece.type];
        } else {
          emptySquares++;
        }
      }

      if (emptySquares > 0) {
        fen += emptySquares.toString();
      }

      if (row < 7) {
        fen += "/";
      }
    }

    fen += ` ${board.playingOrder} `;

    // Add castling options
    const castlingOptions = [
      board.castlingOptions.whiteKingSide ? "K" : "",
      board.castlingOptions.whiteQueenSide ? "Q" : "",
      board.castlingOptions.blackKingSide ? "k" : "",
      board.castlingOptions.blackQueenSide ? "q" : "",
    ];

    const castlingString = castlingOptions.filter(Boolean).join("");
    fen += castlingString.length > 0 ? castlingString : "-";

    fen += " - 0 1";

    return fen;
  };

  const setYellowSelection = (id: string) => {
    if (tertiarySelectedTiles.includes(id)) {
      setTertiarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
    } else {
      setTertiarySelectedTiles((prevValues) => [...prevValues, id]);
      setSecondarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
      setPrimarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
    }
  };

  const setYellowDotSelection = (id: string) => {
    if (tertiaryDottedTiles.includes(id)) {
      setTertiaryDottedTiles((prevValues) => prevValues.filter((value) => value !== id));
    } else {
      setTertiaryDottedTiles((prevValues) => [...prevValues, id]);
    }
  };

  const setGreenDotSelection = (id: string) => {
    if (secondaryDottedTiles.includes(id)) {
      setSecondaryDottedTiles((prevValues) => prevValues.filter((value) => value !== id));
    } else {
      setSecondaryDottedTiles((prevValues) => [...prevValues, id]);
    }
  };

  const setRedDotSelection = (id: string) => {
    if (primaryDottedTiles.includes(id)) {
      setPrimaryDottedTiles((prevValues) => prevValues.filter((value) => value !== id));
    } else {
      setPrimaryDottedTiles((prevValues) => [...prevValues, id]);
    }
  };

  const setGreenSelection = (id: string) => {
    if (secondarySelectedTiles.includes(id)) {
      setSecondarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
    } else {
      setSecondarySelectedTiles((prevValues) => [...prevValues, id]);
      setTertiarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
      setPrimarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
    }
  };

  const setRedSelection = (id: string) => {
    if (primarySelectedTiles.includes(id)) {
      setPrimarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
    } else {
      setPrimarySelectedTiles((prevValues) => [...prevValues, id]);
      setSecondarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
      setTertiarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
    }
  };

  const handleTileClick = (x: number, y: number, id: string) => {
    if (isOptionKeyDown && isShiftKeyDown) {
      setYellowSelection(id);
    } else if (isOptionKeyDown) {
      setGreenSelection(id);
    } else if (isShiftKeyDown) {
      setRedSelection(id);
    } else if (isYKeyDown) {
      setYellowDotSelection(id);
    } else if (isGKeyDown) {
      setGreenDotSelection(id);
    } else if (isRKeyDown) {
      setRedDotSelection(id);
    }

    if (currentSelectionState === SelectionState.FreeSelection) {
    } else if (currentSelectionState === SelectionState.FreeRemove) {
      setBoard((prevBoard) => {
        return {
          ...prevBoard,
          pieces: prevBoard.pieces.filter((value) => !(value.x === x && value.y === y)),
        };
      });
    } else {
      setBoard((prevBoard) => {
        const updatedPieces = [...prevBoard.pieces];
        const existingPieceIndex = updatedPieces.findIndex((piece) => piece.x === x && piece.y === y);

        if (existingPieceIndex !== -1) {
          // If there is already a piece at the selected tile, replace it
          updatedPieces[existingPieceIndex] = {
            x,
            y,
            type: Object.values(PieceType).find((enumValue) => enumValue === currentSelectionState.toString()) as PieceType,
          };
        } else {
          // If there is no piece at the selected tile, add a new one
          updatedPieces.push({
            x,
            y,
            type: Object.values(PieceType).find((enumValue) => enumValue === currentSelectionState.toString()) as PieceType,
          });
        }

        return { ...prevBoard, pieces: updatedPieces };
      });
    }
  };

  const getPieceImgUrl = (x: number, y: number) => {
    const piece = board.pieces.find((value: Piece) => value.x === x && value.y === y);
    if (piece) {
      const iconUrlBase = `/assets/images/pieceSets/${pieceSet}/`;

      switch (piece.type) {
        case PieceType.BKing:
          return iconUrlBase + "bK.svg";
        case PieceType.BQueen:
          return iconUrlBase + "bQ.svg";
        case PieceType.BRook:
          return iconUrlBase + "bR.svg";
        case PieceType.BBishop:
          return iconUrlBase + "bB.svg";
        case PieceType.BKnight:
          return iconUrlBase + "bN.svg";
        case PieceType.BPawn:
          return iconUrlBase + "bP.svg";
        case PieceType.WKing:
          return iconUrlBase + "wK.svg";
        case PieceType.WQueen:
          return iconUrlBase + "wQ.svg";
        case PieceType.WRook:
          return iconUrlBase + "wR.svg";
        case PieceType.WBishop:
          return iconUrlBase + "wB.svg";
        case PieceType.WKnight:
          return iconUrlBase + "wN.svg";
        case PieceType.WPawn:
          return iconUrlBase + "wP.svg";
      }
    }

    return "";
  };

  const getArabicPiece = (piece: Piece) => {
    switch (piece.type) {
      case PieceType.BKing:
        return "شاه";
      case PieceType.BQueen:
        return "فرزين";
      case PieceType.BRook:
        return "رخ";
      case PieceType.BBishop:
        return "فيل";
      case PieceType.BKnight:
        return "فرس";
      case PieceType.BPawn:
        return "بيدق";
      case PieceType.WKing:
        return "شاه";
      case PieceType.WQueen:
        return "فرزين";
      case PieceType.WRook:
        return "رخ";
      case PieceType.WBishop:
        return "فيل";
      case PieceType.WKnight:
        return "فرس";
      case PieceType.WPawn:
        return "بيدق";
    }
  };

  const renderBoard = () => {
    const handleDragOver = (event: any) => {
      event.preventDefault();
    };

    const handleDragStart = (event: any, x: number, y: number, pieceType: PieceType, id: string) => {
      event.dataTransfer.setData("text/plain", JSON.stringify({ x, y, pieceType, id }));
    };

    const handleDrop = (event: any, targetX: number, targetY: number, dropId: string) => {
      event.preventDefault();

      const data = event.dataTransfer.getData("text/plain");
      const { x, y, pieceType, id } = JSON.parse(data);

      if (mode === "game") {
        for (let i = 0; i < board.pieces.length; i++) {
          if (board.pieces[i].x === targetX && board.pieces[i].y === targetY) {
            if (board.pieces[i].type.slice(0, 3) === pieceType.slice(0, 3)) {
              return;
            }
          }
        }

        // lastMove.current = convertToChessPosition(x, y) + convertToChessPosition(targetX, targetY);
        lastMove.current = id + dropId;
        isTurnedBack.current = false;
      }

      // Update the board state with the new coordinates
      setBoard((prevBoard) => {
        const updatedPieces = prevBoard.pieces.map((piece) =>
          piece.x === x && piece.y === y
            ? { ...piece, x: targetX, y: targetY }
            : piece.x === targetX && piece.y === targetY
            ? { ...piece, x: targetX, y: targetY, type: pieceType }
            : piece
        );
        return {
          ...prevBoard,
          pieces: updatedPieces,
        };
      });
    };

    const result = [];
    for (let i = 0; i < colCount; i++) {
      for (let j = 0; j < rowCount; j++) {
        const isLightCell = i % 2 === j % 2;

        const piece = board.pieces.find((value: Piece) => value.x === i && value.y === j);

        const id = `${numberToChar(j)}${colCount - i}`;
        result.push(
          <div
            id={id}
            key={i + "-" + j}
            className={"cell"}
            style={{
              position: "inherit",
              backgroundColor: isLightCell ? boardThemes[backgroundTheme].light : boardThemes[backgroundTheme].dark,
              border: backgroundTheme === "absoluteWhite" ? `${getStrokeWidth() * 0.05}px solid black` : "unset",
            }}
            onClick={() => handleTileClick(i, j, id)}
            onDragOver={(event) => {
              handleDragOver(event);
            }}
            onDrop={(event) => handleDrop(event, i, j, id)}
            draggable={false}
            onMouseDown={() => {
              if (isOptionKeyDown && isShiftKeyDown) {
                setTertiaryArrowStart(id);
                return;
              }

              if (isOptionKeyDown) {
                setSecondaryArrowStart(id);
              }
              if (isShiftKeyDown) {
                setPrimaryArrowStart(id);
              }
            }}
            onMouseUp={() => {
              if (isOptionKeyDown && isShiftKeyDown) {
                const arrow = tertiaryArrowStart + id;
                setTertiaryArrows((prevArrows) =>
                  prevArrows.includes(arrow) ? prevArrows.filter((value) => value !== arrow) : [...prevArrows, arrow]
                );
                setTertiaryArrowStart("");

                if (primaryArrows.includes(arrow)) {
                  setPrimaryArrows((prevArrows) => prevArrows.filter((val) => val !== arrow));
                }

                if (secondaryArrows.includes(arrow)) {
                  setSecondaryArrows((prevArrows) => prevArrows.filter((val) => val !== arrow));
                }

                return;
              }

              if (isOptionKeyDown) {
                const arrow = secondaryArrowStart + id;
                setSecondaryArrows((prevArrows) =>
                  prevArrows.includes(arrow) ? prevArrows.filter((value) => value !== arrow) : [...prevArrows, arrow]
                );
                setSecondaryArrowStart("");

                if (primaryArrows.includes(arrow)) {
                  setPrimaryArrows((prevArrows) => prevArrows.filter((val) => val !== arrow));
                }

                if (tertiaryArrows.includes(id)) {
                  setTertiaryArrows((prevArrows) => prevArrows.filter((val) => val !== arrow));
                }
              }
              if (isShiftKeyDown) {
                const arrow = primaryArrowStart + id;
                setPrimaryArrows((prevArrows) =>
                  prevArrows.includes(arrow) ? prevArrows.filter((value) => value !== arrow) : [...prevArrows, arrow]
                );
                setPrimaryArrowStart("");

                if (secondaryArrows.includes(arrow)) {
                  setSecondaryArrows((prevArrows) => prevArrows.filter((val) => val !== arrow));
                }

                if (tertiaryArrows.includes(arrow)) {
                  setTertiaryArrows((prevArrows) => prevArrows.filter((val) => val !== arrow));
                }
              }
            }}
          >
            {piece &&
              (pieceSet === "arabic" ? (
                <span
                  style={{ zIndex: 1, color: piece.type.includes("black") ? "red" : "black", fontWeight: 500, fontSize: "1.2em" }}
                  draggable={currentSelectionState === SelectionState.FreeSelection && !isOptionKeyDown && !isShiftKeyDown}
                  onDragStart={(event) => handleDragStart(event, i, j, piece.type, id)}
                >
                  {getArabicPiece(piece)}
                </span>
              ) : (
                <img
                  style={{ zIndex: 1 }}
                  src={getPieceImgUrl(i, j)}
                  draggable={currentSelectionState === SelectionState.FreeSelection && !isOptionKeyDown && !isShiftKeyDown}
                  onDragStart={(event) => handleDragStart(event, i, j, piece.type, id)}
                />
              ))}
            {(secondarySelectedTiles.includes(id) || primarySelectedTiles.includes(id) || tertiarySelectedTiles.includes(id)) && (
              <div
                style={{
                  boxSizing: "border-box",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  border: `${getStrokeWidth() * 0.5}px solid ${
                    secondarySelectedTiles.includes(id)
                      ? localPreferences.secondaryColor
                      : tertiarySelectedTiles.includes(id)
                      ? localPreferences.tertiaryColor
                      : localPreferences.primaryColor
                  }`,
                  zIndex: 1,
                }}
              />
            )}
            {(tertiaryDottedTiles.includes(id) || secondaryDottedTiles.includes(id) || primaryDottedTiles.includes(id)) && (
              <div
                style={{
                  boxSizing: "border-box",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  zIndex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    borderRadius: "50%",
                    background: secondaryDottedTiles.includes(id)
                      ? localPreferences.secondaryColor
                      : tertiaryDottedTiles.includes(id)
                      ? localPreferences.tertiaryColor
                      : localPreferences.primaryColor,
                    width: localPreferences.dotRange ? localPreferences.dotRange + "%" : "50%",
                    height: localPreferences.dotRange ? localPreferences.dotRange + "%" : "50%",
                  }}
                />
              </div>
            )}
          </div>
        );
      }
    }
    return result;
  };

  const renderSelectionBar = (type: "white" | "black") => {
    return (
      <>
        <SelectionBtn
          type={SelectionState.FreeSelection}
          setState={setSelectionState}
          isSelected={currentSelectionState === SelectionState.FreeSelection}
        />
        {Object.values(SelectionState)
          .filter((value) => value.startsWith(type))
          .map((value: SelectionState, index: number) => (
            <SelectionBtn key={index} type={value} setState={setSelectionState} isSelected={currentSelectionState === value} />
          ))}
        <SelectionBtn
          type={SelectionState.FreeRemove}
          setState={setSelectionState}
          isSelected={currentSelectionState === SelectionState.FreeRemove}
        />
      </>
    );
  };

  const handleActionClick = (type: "initial" | "clear") => {
    switch (type) {
      case "initial":
        setCurrentFEN(initialFEN);
        break;
      case "clear":
        setCurrentFEN(emptyBoardFEN);
        break;
    }
  };

  const getIndex = (index: number) => {
    if (index % 2 === 0) {
      return "";
    }

    return (index + 1) / 2 + ".";
  };

  const getRuleColor = (color: string) => {
    if (color.toLowerCase() === "#ffffff") {
      return "black";
    }

    return color;
  };

  const clearDrawings = () => {
    setSecondarySelectedTiles([]);
    setPrimarySelectedTiles([]);
    setTertiarySelectedTiles([]);

    setSecondaryArrows([]);
    setPrimaryArrows([]);
    setTertiaryArrows([]);

    setTertiaryDottedTiles([]);
    setSecondaryDottedTiles([]);
    setPrimaryDottedTiles([]);
  };

  const scrollBottomInMovementList = () => {
    if (movementContainerRef.current) {
      movementContainerRef.current.scrollTop = movementContainerRef.current.scrollHeight;
    }
  };

  const getStrokeWidth = () => {
    if (!boardAreaRef.current) return 0;

    return boardAreaRef.current.clientHeight * 0.02;
  };

  return (
    <>
      <div
        className="editor-container"
        // onKeyDown={handleKeyDown}
        // onKeyUp={handleKeyUp}
        tabIndex={0}
        ref={containerDiv}
      >
        <div className="left">
          <div className="left__left" />
          <div className="left__right">
            <div className="board-container">
              <div className={`selection-area ${mode === "game" ? "disabled" : ""}`}>{renderSelectionBar("black")}</div>
              <div
                className="board-area"
                ref={boardAreaRef}
                style={{
                  gridTemplateColumns: `repeat(${rowCount}, 1fr)`,
                  gridTemplateRows: `repeat(${colCount}, 1fr)`,
                  borderWidth: backgroundTheme === "absoluteWhite" ? getStrokeWidth() * 0.1 : getStrokeWidth() * 0.05,
                }}
              >
                {renderBoard()}
                <div
                  className="vertical-ruler"
                  style={{
                    gridTemplateRows: `repeat(${colCount}, 1fr)`,
                    width: getStrokeWidth() + "px",
                    fontSize: getStrokeWidth() + "px",
                  }}
                >
                  {new Array(colCount).fill("").map((val, index) => {
                    return (
                      <span
                        className="rule"
                        style={{
                          color:
                            index % 2 === 0
                              ? colCount % 2 === 0
                                ? getRuleColor(boardThemes[backgroundTheme].light)
                                : getRuleColor(boardThemes[backgroundTheme].dark)
                              : colCount % 2 === 0
                              ? getRuleColor(boardThemes[backgroundTheme].dark)
                              : getRuleColor(boardThemes[backgroundTheme].light),
                        }}
                      >
                        {colCount - index}
                      </span>
                    );
                  })}
                </div>
                <div
                  className="horizontal-ruler"
                  style={{
                    gridTemplateColumns: `repeat(${rowCount}, 1fr)`,
                    height: getStrokeWidth() + "px",
                    fontSize: getStrokeWidth() + "px",
                    bottom: getStrokeWidth() / 3 + "px",
                  }}
                >
                  {new Array(rowCount).fill("").map((val, index) => {
                    return (
                      <span
                        className="rule"
                        style={{
                          color:
                            index % 2 === 0
                              ? rowCount % 2 === 0
                                ? getRuleColor(boardThemes[backgroundTheme].light)
                                : getRuleColor(boardThemes[backgroundTheme].dark)
                              : rowCount % 2 === 0
                              ? getRuleColor(boardThemes[backgroundTheme].dark)
                              : getRuleColor(boardThemes[backgroundTheme].light),
                        }}
                      >
                        {String.fromCharCode(65 + index)}
                      </span>
                    );
                  })}
                </div>
                {primaryArrows.map((arrow, index) => (
                  <ChessArrow
                    key={"primary-" + index}
                    start={arrow.slice(0, 2)}
                    end={arrow.slice(-2)}
                    color={localPreferences.primaryColor}
                    strokeWidth={getStrokeWidth()}
                  />
                ))}
                {secondaryArrows.map((arrow, index) => (
                  <ChessArrow
                    key={"secondary-" + index}
                    start={arrow.slice(0, 2)}
                    end={arrow.slice(-2)}
                    color={localPreferences.secondaryColor}
                    strokeWidth={getStrokeWidth()}
                  />
                ))}
                {tertiaryArrows.map((arrow, index) => (
                  <ChessArrow
                    key={"tertiary-" + index}
                    start={arrow.slice(0, 2)}
                    end={arrow.slice(-2)}
                    color={localPreferences.tertiaryColor}
                    strokeWidth={getStrokeWidth()}
                  />
                ))}
              </div>
              <div className={`selection-area ${mode === "game" ? "disabled" : ""}`}>{renderSelectionBar("white")}</div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="menu-container">
            {mode === "editor" ? (
              <></>
            ) : (
              <div className="movement-container" ref={movementContainerRef}>
                <div className="title">Move List</div>
                <div className="list">
                  {moveList.map((move: any, index: number) => (
                    <div className="item" style={{ flexBasis: "100%" }}>
                      {index > 0 && index + "."}
                      {move.move}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="action-btn-list">
              {mode === "editor" ? (
                <>
                  <DrawingInfoDisplayer />
                  <Button
                    startIcon={<DeleteForeverIcon />}
                    style={{ justifyContent: "flex-start" }}
                    onClick={() => handleActionClick("clear")}
                  >
                    Clear Board
                  </Button>
                  <Button startIcon={<RestartAltIcon />} style={{ justifyContent: "flex-start" }} onClick={() => clearDrawings()}>
                    Clear Drawings
                  </Button>
                  <Button
                    startIcon={<SlideshowIcon />}
                    style={{ justifyContent: "flex-start" }}
                    onClick={() => {
                      setSelectionState(SelectionState.FreeSelection);
                      setMoveList([{ move: "initial", FEN: currentFEN }]);
                      toggleMode();
                    }}
                  >
                    Continue From Here
                  </Button>
                  <Button
                    startIcon={<GetAppIcon />}
                    style={{ justifyContent: "flex-start" }}
                    onClick={() => setExportModalOpen(true)}
                  >
                    Export Board
                  </Button>
                  <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                    <TextField
                      variant="outlined"
                      label="Row Count"
                      type="number"
                      value={colCount}
                      InputProps={{
                        inputProps: {
                          max: 10,
                          min: 1,
                        },
                      }}
                      onChange={(e) => {
                        if (e.target.value) {
                          setColCount(parseInt(e.target.value ?? 0));
                          // setRowCount(parseInt(e.target.value ?? 0));
                        }
                      }}
                    />
                    <TextField
                      variant="outlined"
                      label="Col Count"
                      type="number"
                      value={rowCount}
                      InputProps={{
                        inputProps: {
                          max: 10,
                          min: 1,
                        },
                      }}
                      onChange={(e) => {
                        if (e.target.value) {
                          setRowCount(parseInt(e.target.value ?? 0));
                        }
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <Button
                    startIcon={<RestartAltIcon />}
                    style={{ justifyContent: "flex-start" }}
                    onClick={() => {
                      setMoveList([]);
                      setMode("editor");
                    }}
                  >
                    Return to editor
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal open={isExportModalOpen} onClose={handleClose}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" color="whitesmoke">
            Export Type
          </Typography>
          <div className="mt-3 flex flex-row gap-3">
            <Button variant="outlined" onClick={() => handleExport("svg")}>
              SVG
            </Button>
            <Button variant="outlined" onClick={() => handleExport("jpg")}>
              JPG
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default EditorWithDynamicSize;
