export enum SelectionState {
  FreeSelection = "freeSelection",
  FreeRemove = "freeRemove",
  BKing = "blackKing",
  BQueen = "blackQueen",
  BRook = "blackRook",
  BBishop = "blackBishop",
  BKnight = "blackKnight",
  BPawn = "blackPawn",
  WKing = "whiteKing",
  WQueen = "whiteQueen",
  WRook = "whiteRook",
  WBishop = "whiteBishop",
  WKnight = "whiteKnight",
  WPawn = "whitePawn",
}
