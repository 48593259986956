function numberToChar(num: number) {
  if (num >= 0 && num < 26) {
    // Assuming the input is between 0 and 25
    return String.fromCharCode("a".charCodeAt(0) + num);
  } else {
    return null; // or handle the case when the input is out of bounds
  }
}

export { numberToChar };
