import "./Tile.css";

interface Props {
  image?: string;
  number: number;
  highlight: boolean;
  pieceSet: number;
  boardType: number;
}

export default function Tile({
  number,
  image,
  highlight,
  pieceSet,
  boardType,
}: Props) {
  const blackBGList = ["#779556", "#000000", "#4675AB"];

  const whiteBGList = ["#ebecd0", "#ffffff", "#C3CDD7"];

  const className: string = [
    "tile",
    number % 2 === 0 && "black-tile",
    number % 2 !== 0 && "white-tile",
    highlight && "tile-highlight",
    image && "chess-piece-tile",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div
      className={className}
      style={{
        backgroundColor:
          number % 2 === 0 ? blackBGList[boardType] : whiteBGList[boardType],
      }}
    >
      {image && (
        <div
          style={{
            backgroundImage: `url(${image.replace(
              "piece_set_index",
              pieceSet.toString()
            )})`,
          }}
          className="chess-piece"
        ></div>
      )}
    </div>
  );
}
