import { useEffect, useRef, useState } from "react";
import { initialBoard } from "../../Constants";
import { Piece, Position } from "../../models";
import { Board } from "../../models/Board";
import { Pawn } from "../../models/Pawn";
import {
  bishopMove,
  kingMove,
  knightMove,
  pawnMove,
  queenMove,
  rookMove,
} from "../../referee/rules";
import { PieceType, TeamType } from "../../Types";
import Chessboard from "../Chessboard/Chessboard";
import { Button, TextField } from "@mui/material";

export default function Referee() {
  const [pieceSet, setPieceSet] = useState<number>(1);
  const [boardType, setBoardType] = useState<number>(0);

  const [board, setBoard] = useState<Board>(initialBoard.clone());
  const [promotionPawn, setPromotionPawn] = useState<Piece>();
  const [fenRepresentation, setFenRepresentation] = useState<string>();
  const modalRef = useRef<HTMLDivElement>(null);
  const checkmateModalRef = useRef<HTMLDivElement>(null);
  const fenChangedByUser = useRef<boolean>(false);

  function boardToFEN() {
    const pieceTypeToSymbol = {
      pawn: "p",
      knight: "n",
      bishop: "b",
      rook: "r",
      queen: "q",
      king: "k",
    };

    let fen = "";

    // Convert piece positions
    for (let rank = 7; rank >= 0; rank--) {
      let emptySquares = 0;
      for (let file = 0; file < 8; file++) {
        const piece = board.pieces.find(
          (p) => p.position.x === file && p.position.y === rank
        );
        if (piece) {
          if (emptySquares > 0) {
            fen += emptySquares;
            emptySquares = 0;
          }
          console.log(piece.type);
          const symbol = pieceTypeToSymbol[piece.type];
          fen += piece.team === "w" ? symbol.toUpperCase() : symbol;
        } else {
          emptySquares++;
        }
      }
      if (emptySquares > 0) {
        fen += emptySquares;
      }
      if (rank > 0) {
        fen += "/";
      }
    }

    // Add active color
    fen += ` ${board.totalTurns % 2 === 0 ? "w" : "b"}`;

    // Add castling availability
    fen += " -";

    // Add en passant target square
    fen += " -";

    // Add halfmove clock
    fen += " 0";

    // Add fullmove number
    fen += ` ${Math.floor(board.totalTurns / 2) + 1}`;

    return fen;
  }

  function getPieceType(char: string) {
    switch (char.toLowerCase()) {
      case "p":
        return PieceType.PAWN;
      case "b":
        return PieceType.BISHOP;
      case "n":
        return PieceType.KNIGHT;
      case "r":
        return PieceType.ROOK;
      case "q":
        return PieceType.QUEEN;
      case "k":
        return PieceType.KING;
      default:
        return PieceType.PAWN; // Handle unknown piece types if needed
    }
  }

  function FENToBoard() {
    const [
      piecePlacement,
      activeColor,
      castling,
      enPassant,
      halfMoveClock,
      fullMoveNumber,
    ] = (fenRepresentation ?? "").split(" ");

    const pieces = [];
    let rank = 7;
    let file = 0;

    for (let i = 0; i < piecePlacement.length; i++) {
      const char = piecePlacement[i];

      if (char === "/") {
        rank--;
        file = 0;
      } else if (char >= "1" && char <= "8") {
        file += parseInt(char, 10);
      } else {
        const team =
          char === char.toUpperCase() ? TeamType.OUR : TeamType.OPPONENT;
        const type = getPieceType(char);
        const position = new Position(file, rank);

        if (type === PieceType.PAWN) {
          pieces.push(new Pawn(position, team, false));
        } else {
          pieces.push(new Piece(position, type, team, false));
        }

        file++;
      }
    }

    return new Board(
      pieces,
      (parseInt(fullMoveNumber, 10) - 1) * 2 + (activeColor === "w" ? 0 : 1)
    );
  }

  useEffect(() => {
    setFenRepresentation(boardToFEN());
  }, [board]);

  useEffect(() => {
    if (fenChangedByUser.current) {
      const tempBoard = FENToBoard();
      //   console.log(tempBoard);
      setBoard(tempBoard);
      fenChangedByUser.current = false;
    }
  }, [fenRepresentation]);

  function playMove(playedPiece: Piece, destination: Position): boolean {
    // If the playing piece doesn't have any moves return
    if (playedPiece.possibleMoves === undefined) return false;

    // Prevent the inactive team from playing
    if (playedPiece.team === TeamType.OUR && board.totalTurns % 2 !== 1)
      return false;
    if (playedPiece.team === TeamType.OPPONENT && board.totalTurns % 2 !== 0)
      return false;

    let playedMoveIsValid = false;

    const validMove = playedPiece.possibleMoves?.some((m) =>
      m.samePosition(destination)
    );

    if (!validMove) return false;

    const enPassantMove = isEnPassantMove(
      playedPiece.position,
      destination,
      playedPiece.type,
      playedPiece.team
    );

    // playMove modifies the board thus we
    // need to call setBoard
    setBoard(() => {
      const clonedBoard = board.clone();
      clonedBoard.totalTurns += 1;
      // Playing the move
      playedMoveIsValid = clonedBoard.playMove(
        enPassantMove,
        validMove,
        playedPiece,
        destination
      );

      if (clonedBoard.winningTeam !== undefined) {
        checkmateModalRef.current?.classList.remove("hidden");
      }

      return clonedBoard;
    });

    // This is for promoting a pawn
    let promotionRow = playedPiece.team === TeamType.OUR ? 7 : 0;

    if (destination.y === promotionRow && playedPiece.isPawn) {
      modalRef.current?.classList.remove("hidden");
      setPromotionPawn((previousPromotionPawn) => {
        const clonedPlayedPiece = playedPiece.clone();
        clonedPlayedPiece.position = destination.clone();
        return clonedPlayedPiece;
      });
    }

    return playedMoveIsValid;
  }

  function isEnPassantMove(
    initialPosition: Position,
    desiredPosition: Position,
    type: PieceType,
    team: TeamType
  ) {
    const pawnDirection = team === TeamType.OUR ? 1 : -1;

    if (type === PieceType.PAWN) {
      if (
        (desiredPosition.x - initialPosition.x === -1 ||
          desiredPosition.x - initialPosition.x === 1) &&
        desiredPosition.y - initialPosition.y === pawnDirection
      ) {
        const piece = board.pieces.find(
          (p) =>
            p.position.x === desiredPosition.x &&
            p.position.y === desiredPosition.y - pawnDirection &&
            p.isPawn &&
            (p as Pawn).enPassant
        );
        if (piece) {
          return true;
        }
      }
    }

    return false;
  }

  //TODO
  //Add stalemate!
  function isValidMove(
    initialPosition: Position,
    desiredPosition: Position,
    type: PieceType,
    team: TeamType
  ) {
    let validMove = false;
    switch (type) {
      case PieceType.PAWN:
        validMove = pawnMove(
          initialPosition,
          desiredPosition,
          team,
          board.pieces
        );
        break;
      case PieceType.KNIGHT:
        validMove = knightMove(
          initialPosition,
          desiredPosition,
          team,
          board.pieces
        );
        break;
      case PieceType.BISHOP:
        validMove = bishopMove(
          initialPosition,
          desiredPosition,
          team,
          board.pieces
        );
        break;
      case PieceType.ROOK:
        validMove = rookMove(
          initialPosition,
          desiredPosition,
          team,
          board.pieces
        );
        break;
      case PieceType.QUEEN:
        validMove = queenMove(
          initialPosition,
          desiredPosition,
          team,
          board.pieces
        );
        break;
      case PieceType.KING:
        validMove = kingMove(
          initialPosition,
          desiredPosition,
          team,
          board.pieces
        );
    }

    return validMove;
  }

  function promotePawn(pieceType: PieceType) {
    if (promotionPawn === undefined) {
      return;
    }

    setBoard((previousBoard) => {
      const clonedBoard = board.clone();
      clonedBoard.pieces = clonedBoard.pieces.reduce((results, piece) => {
        if (piece.samePiecePosition(promotionPawn)) {
          results.push(
            new Piece(piece.position.clone(), pieceType, piece.team, true)
          );
        } else {
          results.push(piece);
        }
        return results;
      }, [] as Piece[]);

      clonedBoard.calculateAllMoves();

      return clonedBoard;
    });

    modalRef.current?.classList.add("hidden");
  }

  function promotionTeamType() {
    return promotionPawn?.team === TeamType.OUR ? "w" : "b";
  }

  function restartGame() {
    checkmateModalRef.current?.classList.add("hidden");
    setBoard(initialBoard.clone());
  }

  return (
    <>
      <div className="modal hidden" ref={modalRef}>
        <div className="modal-body">
          <img
            onClick={() => promotePawn(PieceType.ROOK)}
            src={`/assets/images/pieceSets/${pieceSet}/rook_${promotionTeamType()}.png`}
          />
          <img
            onClick={() => promotePawn(PieceType.BISHOP)}
            src={`/assets/images/pieceSets/${pieceSet}/bishop_${promotionTeamType()}.png`}
          />
          <img
            onClick={() => promotePawn(PieceType.KNIGHT)}
            src={`/assets/images/pieceSets/${pieceSet}/knight_${promotionTeamType()}.png`}
          />
          <img
            onClick={() => promotePawn(PieceType.QUEEN)}
            src={`/assets/images/pieceSets/${pieceSet}/queen_${promotionTeamType()}.png`}
          />
        </div>
      </div>
      <div className="modal hidden" ref={checkmateModalRef}>
        <div className="modal-body">
          <div className="checkmate-body">
            <span>
              The winning team is{" "}
              {board.winningTeam === TeamType.OUR ? "white" : "black"}!
            </span>
            <button onClick={restartGame}>Play again</button>
          </div>
        </div>
      </div>
      <Chessboard
        playMove={playMove}
        pieces={board.pieces}
        pieceSet={pieceSet}
        boardType={boardType}
      />
      <div style={{ width: 600 }}>
        FEN:
        <TextField
          value={fenRepresentation}
          fullWidth
          inputProps={{ color: "white" }}
          onChange={(e) => {
            fenChangedByUser.current = true;
            setFenRepresentation(e.target.value);
          }}
        />
      </div>
      <div style={{ position: "fixed", left: 600, top: 100 }}>
        <Button onClick={() => setPieceSet((prevValue) => prevValue + 1)}>
          Change Piece Set
        </Button>
      </div>
      <div style={{ position: "fixed", left: 600, top: 200 }}>
        <Button onClick={() => setBoardType((prevValue) => prevValue + 1)}>
          Change Board Type
        </Button>
      </div>
    </>
  );
}
