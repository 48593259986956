import { Box, Modal, Slider, Typography } from "@mui/material";
import { Sketch } from "@uiw/react-color";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/types";
import { useDispatch } from "react-redux";
import { setAnalysisPreferences } from "../../redux/boardSlice";
import { useEffect, useState } from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 20,
};

const SelectColorModal = ({ isOpen, setOpen }: { isOpen: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const { analysisPreferences } = useSelector((state: RootState) => state.board);
  const dispatch = useDispatch();

  const [isPrimaryOpen, setPrimaryOpen] = useState<boolean>(false);
  const togglePrimary = () => setPrimaryOpen((prevVal) => !prevVal);

  const [isSecondaryOpen, setSecondaryOpen] = useState<boolean>(false);
  const toggleSecondary = () => setSecondaryOpen((prevVal) => !prevVal);

  const [isTertiaryOpen, setTertiaryOpen] = useState<boolean>(false);
  const toggleTertiary = () => setTertiaryOpen((prevVal) => !prevVal);

  useEffect(() => {
    if (!isOpen) {
      setPrimaryOpen(false);
      setSecondaryOpen(false);
      setTertiaryOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const handleClick = (ev: MouseEvent) => {
    if (
      hasParentWithId(ev.target as HTMLElement, "primary-color-selector") ||
      hasParentWithId(ev.target as HTMLElement, "primary-color-displayer") ||
      hasParentWithId(ev.target as HTMLElement, "secondary-color-selector") ||
      hasParentWithId(ev.target as HTMLElement, "secondary-color-displayer") ||
      hasParentWithId(ev.target as HTMLElement, "tertiary-color-selector") ||
      hasParentWithId(ev.target as HTMLElement, "tertiary-color-displayer")
    ) {
      return;
    }

    setPrimaryOpen(false);
    setSecondaryOpen(false);
    setTertiaryOpen(false);
  };

  function hasParentWithId(node: HTMLElement, parentId: string): boolean {
    let current: HTMLElement | null = node;

    while (current !== null) {
      if (current.id === parentId) {
        return true;
      }
      current = current.parentElement;
    }

    return false;
  }

  const handleClose = () => setOpen(false);

  const handleColorChange = (newColor: any, type: "primaryColor" | "secondaryColor" | "tertiaryColor" | "dotRange") => {
    const localPreferences: any = analysisPreferences
      ? { ...analysisPreferences }
      : {
          primaryColor: "#ff0000",
          secondaryColor: "#00ff00",
          tertiaryColor: "#ffff00",
          dotRange: 50,
        };

    localPreferences[type] = newColor;

    dispatch(setAnalysisPreferences(localPreferences));
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      keepMounted={false}
    >
      <Box sx={style}>
        <div id="select-modal" style={{ fontSize: "0.8em" }}>
          <Typography id="modal-modal-title" variant="h6" component="h1" color={"whitesmoke"}>
            Analysis Color Preferences
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              flexWrap: "wrap",
              marginTop: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                color: "whitesmoke",
                width: "100%",
                gap: "20px",
                position: "relative",
              }}
            >
              <div>Primary Color:</div>
              <div>
                <div
                  id="primary-color-displayer"
                  style={{
                    width: "75px",
                    height: "25px",
                    cursor: "pointer",
                    background: analysisPreferences ? analysisPreferences.primaryColor : "#ff0000",
                  }}
                  onClick={() => {
                    setSecondaryOpen(false);
                    setTertiaryOpen(false);
                    togglePrimary();
                  }}
                />
                <div style={{ position: "absolute", display: isPrimaryOpen ? "block" : "none", zIndex: 1 }}>
                  <Sketch
                    id="primary-color-selector"
                    color={analysisPreferences ? analysisPreferences.primaryColor : "#ff0000"}
                    onChange={(newColor) => handleColorChange(newColor.hexa, "primaryColor")}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                color: "whitesmoke",
                width: "100%",
                gap: "20px",
                position: "relative",
              }}
            >
              <div>Secondary Color:</div>
              <div>
                <div
                  id="secondary-color-displayer"
                  style={{
                    width: "75px",
                    height: "25px",
                    cursor: "pointer",
                    background: analysisPreferences ? analysisPreferences.secondaryColor : "#00ff00",
                  }}
                  onClick={() => {
                    toggleSecondary();
                    setPrimaryOpen(false);
                    setTertiaryOpen(false);
                  }}
                />
                <div style={{ position: "absolute", display: isSecondaryOpen ? "block" : "none", zIndex: 1 }}>
                  <Sketch
                    id="secondary-color-selector"
                    color={analysisPreferences ? analysisPreferences.secondaryColor : "#00ff00"}
                    onChange={(newColor) => handleColorChange(newColor.hexa, "secondaryColor")}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                color: "whitesmoke",
                width: "100%",
                gap: "20px",
                position: "relative",
              }}
            >
              <div>Tertiary Color:</div>
              <div>
                <div
                  id="tertiary-color-displayer"
                  style={{
                    width: "75px",
                    height: "25px",
                    cursor: "pointer",
                    background: analysisPreferences ? analysisPreferences.tertiaryColor : "#ffff00",
                  }}
                  onClick={() => {
                    setPrimaryOpen(false);
                    setSecondaryOpen(false);
                    toggleTertiary();
                  }}
                />
                <div style={{ position: "absolute", display: isTertiaryOpen ? "block" : "none", zIndex: 1 }}>
                  <Sketch
                    id="tertiary-color-selector"
                    color={analysisPreferences ? analysisPreferences.tertiaryColor : "#ffff00"}
                    onChange={(newColor) => handleColorChange(newColor.hexa, "tertiaryColor")}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                color: "whitesmoke",
                width: "100%",
                gap: "20px",
                position: "relative",
              }}
            >
              <div>Dot Range:</div>
              <div style={{ width: "50%" }}>
                <Slider
                  size="small"
                  defaultValue={analysisPreferences.dotRange ? analysisPreferences.dotRange : 50}
                  value={analysisPreferences.dotRange ? analysisPreferences.dotRange : 50}
                  onChange={(event, value) => handleColorChange(value, "dotRange")}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                  min={20}
                  max={80}
                />
              </div>
            </div>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default SelectColorModal;
