import { useDispatch } from "react-redux";
import { setBackgroundTheme } from "../../redux/boardSlice";

const ThemeDisplayer = ({
  name,
  firstColor,
  secondColor,
  afterClick,
}: {
  name: string;
  firstColor: string;
  secondColor: string;
  afterClick: Function;
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setBackgroundTheme(name));
    afterClick();
  };

  return (
    <div
      style={{
        width: "70px",
        height: "30px",
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
        gap: "1px",
      }}
      onClick={handleClick}
    >
      <div style={{ backgroundColor: firstColor, flex: 1 }} />
      <div style={{ backgroundColor: secondColor, flex: 1 }} />
    </div>
  );
};

export default ThemeDisplayer;
