const pieceSets = [
  "shahi-color",
  "shahi-white",
  "alfonso",
  "dubrovny",
  "arabic",
  // "alpha",
  // "anarcandy",
  // "caliente",
  // "california",
  // "cardinal",
  // "cburnett",
  // "chess7",
  // "chessnut",
  // "companion",
  // "fantasy",
  // "fresca",
  // "gioco",
  // "governor",
  // "icpieces",
  // "kiwen-suwi",
  // "kosal",
  // "leipzig",
  // "libra",
  // "maestro",
  // "merida",
  // "mpchess",
  // "pirouetti",
  // "pixel",
  // "reillycraig",
  // "riohacha",
  // "spatial",
  // "staunty",
  // "tatiana",
];

const boardThemes: any = {
  initial: { dark: "#B58862", light: "#F0D9B5" },
  ocean: { dark: "#8CA1AD", light: "#DEE3E6" },
  green: { dark: "#779556", light: "#EBECD0" },
  pink: { dark: "#F37A7A", light: "#EEEFBB" },
  lightgreen: { dark: "#C1C18E", light: "#ECECEC" },
  alfonso: { dark: "#883233", light: "#CCCCCC" },
  white: { dark: "#eeeeee", light: "#ffffff" },
  absoluteWhite: { dark: "#ffffff", light: "#ffffff" },
  gray: { dark: "#BBBBBB", light: "#7B7B7B" },
  binary: { dark: "#000000", light: "#ffffff" },
  purple: { dark: "#7D498D", light: "#9E90B0" },
};

const preDefinedGames = [
  {
    gameName: "test-game-1",
    moveList: [
      {
        move: "initial",
        FEN: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
      },
      {
        move: "e2e4",
        color: "w",
        FEN: "rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 1",
      },
      {
        move: "f7f6",
        color: "b",
        FEN: "rnbqkbnr/ppppp1pp/5p2/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 1",
      },
      {
        move: "e4e5",
        color: "w",
        FEN: "rnbqkbnr/ppppp1pp/5p2/4P3/8/8/PPPP1PPP/RNBQKBNR w KQkq - 0 1",
      },
      {
        move: "f6e5",
        color: "b",
        FEN: "rnbqkbnr/ppppp1pp/8/4p3/8/8/PPPP1PPP/RNBQKBNR w KQkq - 0 1",
      },
      {
        move: "d2d3",
        color: "w",
        FEN: "rnbqkbnr/ppppp1pp/8/4p3/8/3P4/PPP2PPP/RNBQKBNR w KQkq - 0 1",
      },
      {
        move: "e5e4",
        color: "b",
        FEN: "rnbqkbnr/ppppp1pp/8/8/4p3/3P4/PPP2PPP/RNBQKBNR w KQkq - 0 1",
      },
      {
        move: "d3e4",
        color: "w",
        FEN: "rnbqkbnr/ppppp1pp/8/8/4P3/8/PPP2PPP/RNBQKBNR w KQkq - 0 1",
      },
    ],
  },
  {
    gameName: "test-game-2",
    moveList: [
      {
        move: "initial",
        FEN: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
      },
      {
        move: "e2e4",
        color: "w",
        FEN: "rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 1",
      },
      {
        move: "f7f6",
        color: "b",
        FEN: "rnbqkbnr/ppppp1pp/5p2/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 1",
      },
      {
        move: "e4e5",
        color: "w",
        FEN: "rnbqkbnr/ppppp1pp/5p2/4P3/8/8/PPPP1PPP/RNBQKBNR w KQkq - 0 1",
      },
      {
        move: "f6e5",
        color: "b",
        FEN: "rnbqkbnr/ppppp1pp/8/4p3/8/8/PPPP1PPP/RNBQKBNR w KQkq - 0 1",
      },
      {
        move: "d2d3",
        color: "w",
        FEN: "rnbqkbnr/ppppp1pp/8/4p3/8/3P4/PPP2PPP/RNBQKBNR w KQkq - 0 1",
      },
      {
        move: "e5e4",
        color: "b",
        FEN: "rnbqkbnr/ppppp1pp/8/8/4p3/3P4/PPP2PPP/RNBQKBNR w KQkq - 0 1",
      },
      {
        move: "d3e4",
        color: "w",
        FEN: "rnbqkbnr/ppppp1pp/8/8/4P3/8/PPP2PPP/RNBQKBNR w KQkq - 0 1",
      },
    ],
  },
];

export { pieceSets, boardThemes, preDefinedGames };
