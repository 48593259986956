// PopperButton.tsx
import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";
import InfoIcon from "@mui/icons-material/Info";
import "./scss/drawingInfoDisplayer.scss";

const DrawingInfoDisplayer: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <div>
      <Button
        startIcon={<InfoIcon />}
        style={{ justifyContent: "flex-start" }}
        aria-describedby={id}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        Drawing Information
      </Button>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <div
          style={{
            background: "#121212",
            color: "white",
            borderRadius: "8px",
            padding: "8px",
            fontSize: "0.8em",
            maxWidth: "500px",
            zIndex: 99999,
          }}
        >
          Here is the drawing instructions:<br></br>- Press SHIFT and draw/select on the board for PRIMARY color.<br></br>- Press
          OPTION/ALT and draw/select on the board for SECONDARY color.<br></br>- Press BOTH(primary and secondary buttons) and
          draw/select on the board for TERTIARY color.<br></br>
          <br></br>- Press P button and select tile for dotting with PRIMARY color.<br></br>- Press S button and select tile for
          dotting with SECONDARY color.<br></br>- Press T button and select tile for dotting with TERTIARY color.<br></br>
          <br></br>- Press DEL button for delete all drawings
        </div>
      </Popper>
    </div>
  );
};

export default DrawingInfoDisplayer;
