import { IconButton, Menu, MenuItem, Popover } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useState } from "react";
import SelectBoardThemeModal from "../HeaderMenu/SelectBoardThemeModal";
import SelectPieceSetModal from "../HeaderMenu/SelectPieceSetModal";
import SelectColorModal from "../HeaderMenu/SelectColorModal";

const SettingsBarMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [isBoardThemeModalOpen, setBoardThemeModalOpen] = useState<boolean>(false);
  const [isPieceSetModalOpen, setPieceSetModalOpen] = useState<boolean>(false);
  const [isColorSelectionOpen, setColorSelectionOpen] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <SelectPieceSetModal isOpen={isPieceSetModalOpen} setOpen={setPieceSetModalOpen} />
      <SelectBoardThemeModal isOpen={isBoardThemeModalOpen} setOpen={setBoardThemeModalOpen} />
      <SelectColorModal isOpen={isColorSelectionOpen} setOpen={setColorSelectionOpen} />
      <IconButton onClick={handleClick}>
        <SettingsIcon color="primary" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        disableScrollLock
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setBoardThemeModalOpen(true);
          }}
        >
          Board Theme
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setPieceSetModalOpen(true);
          }}
        >
          Piece Set
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setColorSelectionOpen(true);
          }}
        >
          Analysis Color Preferences
        </MenuItem>
      </Menu>
    </>
  );
};

export default SettingsBarMenu;
