import "./App.css";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { routePaths, routes } from "./routes/routes";
import { RouteType } from "./routes/routeType";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Layout from "./components/Layout/Layout";
import { useEffect } from "react";

const theme = createTheme({
  spacing: 1,
  typography: {
    fontSize: 12,
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#999999",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#404040",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#ccc", // Label color
            borderColor: "#404040",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ccc",
          },
          "& .MuiInputBase-input": {
            color: "#ccc",
            borderColor: "#404040",
            padding: "8px 15px",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#404040",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ccc",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ccc",
          },
          ".MuiSvgIcon-root ": {
            fill: "white !important",
          },
          "& .MuiInputBase-input": {
            color: "#ccc",
            borderColor: "#404040",
            padding: "8px 15px",
          },
        },
      },
    },
  },
});

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate(routePaths.EditorPage);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Routes>
          {routes.map((route: RouteType, index: number) => (
            <Route key={index} {...route} />
          ))}
        </Routes>
      </Layout>
    </ThemeProvider>
  );
};

export default App;
