import { Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../routes/routes";

import "./scss/headerMenu.scss";
import SettingsBarMenu from "../SettingsBarMenu/SettingsBarMenu";

const HeaderBar = () => {
  const navigate = useNavigate();

  const handleNavigate = (route: routePaths) => {
    navigate(route);
  };

  return (
    <nav className="header-container">
      {/* <Button
        variant="text"
        onClick={() => handleNavigate(routePaths.LandingPage)}
      >
        Landing
      </Button>
      <Button
        variant="text"
        onClick={() => handleNavigate(routePaths.TournamentManagement)}
      >
        Tournament
      </Button>
      <Button
        variant="text"
        onClick={() => handleNavigate(routePaths.AnalysisPage)}
      >
        Analysis
      </Button> */}

      <Button variant="text" onClick={() => handleNavigate(routePaths.EditorPage)}>
        Board Editor
      </Button>
      <Button variant="text" onClick={() => handleNavigate(routePaths.EditorWithDynamicSizePage)}>
        NxN Matrix
      </Button>
      <Button variant="text" onClick={() => handleNavigate(routePaths.HomeworkGenerator)}>
        Homework Generator
      </Button>
      <Button variant="text" onClick={() => handleNavigate(routePaths.SavedGames)}>
        Saved Games
      </Button>
      <div className="end-container">
        <SettingsBarMenu />
      </div>
    </nav>
  );
};

export default HeaderBar;
