import { SelectionState } from "../../types/SelectionStates";
import { SelectionBtnProps } from "./SelectionBtnProps";
import PanToolIcon from "@mui/icons-material/PanTool";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import "./scss/selectionBtn.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/types";

const SelectionBtn = (selectionBtnProps: SelectionBtnProps) => {
  const pieceSet = useSelector((state: RootState) => state.board.pieceSet);

  const { type, isSelected, setState } = selectionBtnProps;
  const iconUrlBase = `/assets/images/pieceSets/${pieceSet}/`;

  const getIconUrl = () => {
    switch (type) {
      case SelectionState.BKing:
        return "bK.svg";
      case SelectionState.BQueen:
        return "bQ.svg";
      case SelectionState.BRook:
        return "bR.svg";
      case SelectionState.BBishop:
        return "bB.svg";
      case SelectionState.BKnight:
        return "bN.svg";
      case SelectionState.BPawn:
        return "bP.svg";
      case SelectionState.WKing:
        return "wK.svg";
      case SelectionState.WQueen:
        return "wQ.svg";
      case SelectionState.WRook:
        return "wR.svg";
      case SelectionState.WBishop:
        return "wB.svg";
      case SelectionState.WKnight:
        return "wN.svg";
      case SelectionState.WPawn:
        return "wP.svg";
    }
  };

  const getArabicIcon = () => {
    switch (type) {
      case SelectionState.BKing:
        return "شاه";
      case SelectionState.BQueen:
        return "فرزان";
      case SelectionState.BRook:
        return "رخ";
      case SelectionState.BBishop:
        return "فيل";
      case SelectionState.BKnight:
        return "فرس";
      case SelectionState.BPawn:
        return "بيدق";
      case SelectionState.WKing:
        return "شاه";
      case SelectionState.WQueen:
        return "فرزان";
      case SelectionState.WRook:
        return "رخ";
      case SelectionState.WBishop:
        return "فيل";
      case SelectionState.WKnight:
        return "فرس";
      case SelectionState.WPawn:
        return "بيدق";
    }
  };

  const renderIcon = () => {
    switch (type) {
      case SelectionState.FreeSelection:
        return (
          <span className="custom-icon select">
            <PanToolIcon fontSize="inherit" />
          </span>
        );
      case SelectionState.FreeRemove:
        return (
          <span className="custom-icon remove">
            <DeleteForeverIcon fontSize="inherit" />
          </span>
        );
      default:
        return pieceSet === "arabic" ? (
          <span style={{ zIndex: 1, color: type.includes("black") ? "red" : "black", fontWeight: 500, fontSize: "1.2em" }}>
            {getArabicIcon()}
          </span>
        ) : (
          <img alt="select button piece" src={iconUrlBase + getIconUrl()} draggable={false} />
        );
    }
  };

  const handleClick = () => {
    setState(type);
  };

  return (
    <div
      className={`selection-btn ${isSelected ? (type === SelectionState.FreeRemove ? "selected-remove" : "selected") : ""}`}
      onClick={handleClick}
    >
      {renderIcon()}
    </div>
  );
};

export default SelectionBtn;
