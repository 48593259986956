import { Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { setBackgroundTheme, setPieceSet } from "../../redux/boardSlice";

const PieceSetDisplayer = ({ name, afterClick }: { name: string; afterClick: Function }) => {
  const iconUrl = `/assets/images/pieceSets/${name}/bN.svg`;
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setPieceSet(name));
    if (name === "shahi-color") {
      dispatch(setBackgroundTheme("initial"));
    } else if (name === "shahi-white") {
      dispatch(setBackgroundTheme("green"));
    }

    afterClick();
  };

  return (
    <Tooltip title={name}>
      <div
        style={{
          background: "white",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={handleClick}
      >
        {<img src={iconUrl} width={50} height={50} />}
      </div>
    </Tooltip>
  );
};

export default PieceSetDisplayer;
