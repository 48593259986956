import { Box, Modal, Typography } from "@mui/material";
import { boardThemes } from "../../constants";
import ThemeDisplayer from "../ThemeDisplayer/ThemeDisplayer";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 20,
};

const SelectBoardThemeModal = ({
  isOpen,
  setOpen,
}: {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h1"
          color={"whitesmoke"}
        >
          Select Board Theme
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            flexWrap: "wrap",
            marginTop: 20,
          }}
        >
          {Object.keys(boardThemes).map((key: any, index: number) => (
            <ThemeDisplayer
              key={index}
              name={key}
              firstColor={boardThemes[key].light}
              secondColor={boardThemes[key].dark}
              afterClick={() => setOpen(false)}
            />
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default SelectBoardThemeModal;
