import { Box, MenuItem, Modal, Select, Typography, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { boardThemes, preDefinedGames } from "../../constants";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/types";
import ChessArrow from "../../components/ChessArrow/ChessArrow";
import { Board, Piece, PieceType } from "../Editor/types/Board";
import { numberToChar } from "../../utils/numberToChar";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 20,
};

const SavedGames = () => {
  const [gameState, setGameState] = useState<any>(undefined);
  const [gameSelectModalOpen, setGameSelectModalOpen] = useState(true);
  const [selectedGameName, setSelectedGameName] = useState<string>("initial");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [parsedJson, setParsedJson] = useState<any>(null);

  const { pieceSet, backgroundTheme, analysisPreferences } = useSelector((state: RootState) => state.board);

  const localPreferences = analysisPreferences ?? {
    primaryColor: "#ff0000",
    secondaryColor: "#00ff00",
    tertiaryColor: "#ffff00",
    dotRange: 50,
  };

  const containerDiv = useRef<HTMLDivElement>(null);
  const boardAreaRef = useRef<HTMLDivElement>(null);

  const [primarySelectedTiles, setPrimarySelectedTiles] = useState<string[]>([]);
  const [primaryDottedTiles, setPrimaryDottedTiles] = useState<string[]>([]);
  const [primaryArrowStart, setPrimaryArrowStart] = useState<string>("");
  const [primaryArrows, setPrimaryArrows] = useState<string[]>([]);

  const [secondarySelectedTiles, setSecondarySelectedTiles] = useState<string[]>([]);
  const [secondaryDottedTiles, setSecondaryDottedTiles] = useState<string[]>([]);
  const [secondaryArrowStart, setSecondaryArrowStart] = useState<string>("");
  const [secondaryArrows, setSecondaryArrows] = useState<string[]>([]);

  const [tertiarySelectedTiles, setTertiarySelectedTiles] = useState<string[]>([]);
  const [tertiaryDottedTiles, setTertiaryDottedTiles] = useState<string[]>([]);
  const [tertiaryArrowStart, setTertiaryArrowStart] = useState<string>("");
  const [tertiaryArrows, setTertiaryArrows] = useState<string[]>([]);

  const [isOptionKeyDown, setIsOptionKeyDown] = useState<boolean>(false);
  const [isShiftKeyDown, setIsShiftKeyDown] = useState<boolean>(false);
  const [isGKeyDown, setIsGKeyDown] = useState<boolean>(false);
  const [isRKeyDown, setIsRKeyDown] = useState<boolean>(false);
  const [isYKeyDown, setIsYKeyDown] = useState<boolean>(false);

  const [currentMoveIndex, setCurrentMoveIndex] = useState<number>(0);

  const [board, setBoard] = useState<Board>({
    pieces: [],
    playingOrder: "w",

    castlingOptions: {
      whiteKingSide: false,
      whiteQueenSide: false,
      blackKingSide: false,
      blackQueenSide: false,
    },
  });

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
  }, []);

  const clearDrawings = () => {
    setSecondarySelectedTiles([]);
    setPrimarySelectedTiles([]);
    setTertiarySelectedTiles([]);

    setSecondaryArrows([]);
    setPrimaryArrows([]);
    setTertiaryArrows([]);

    setTertiaryDottedTiles([]);
    setSecondaryDottedTiles([]);
    setPrimaryDottedTiles([]);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      clearDrawings();
    }

    if (e.key === "s") {
      setIsGKeyDown(true);
    }

    if (e.key === "p") {
      setIsRKeyDown(true);
    }

    if (e.key === "t") {
      setIsYKeyDown(true);
    }

    if (e.key === "Alt") {
      setIsOptionKeyDown(true);
    }

    if (e.key === "Shift") {
      setIsShiftKeyDown(true);
    }
  };

  const handleKeyUp = (e: any) => {
    // Reset the state when the option key is released
    if (e.key === "s") {
      setIsGKeyDown(false);
    }

    if (e.key === "p") {
      setIsRKeyDown(false);
    }

    if (e.key === "t") {
      setIsYKeyDown(false);
    }

    if (e.key === "Alt") {
      setIsOptionKeyDown(false);
    }
    if (e.key === "Shift") {
      setIsShiftKeyDown(false);
    }
  };

  useEffect(() => {
    setGameSelectModalOpen(true);
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type === "application/json") {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target?.result as string);
          setParsedJson(json);
          setSelectedGameName("initial");
        } catch (error) {
          console.error("Error parsing JSON", error);
          setSelectedFile(null);
        }
      };
      reader.readAsText(file);
    } else {
      setSelectedFile(null);
      setParsedJson(null);
    }
  };

  const FENToBoard = (fen: string): Board => {
    const fenParts = fen.split(" ");

    if (fenParts.length !== 6) {
      throw new Error("Invalid FEN string");
    }

    const pieceTypeFromFEN: { [key: string]: PieceType } = {
      k: PieceType.BKing,
      q: PieceType.BQueen,
      r: PieceType.BRook,
      b: PieceType.BBishop,
      n: PieceType.BKnight,
      p: PieceType.BPawn,
      K: PieceType.WKing,
      Q: PieceType.WQueen,
      R: PieceType.WRook,
      B: PieceType.WBishop,
      N: PieceType.WKnight,
      P: PieceType.WPawn,
    };

    const [piecePlacement, turn, castling, enPassant, halfMove, fullMove] = fenParts;

    const pieces: Piece[] = [];
    let row = 0;
    let col = 0;

    for (const char of piecePlacement) {
      if (char === "/") {
        row++;
        col = 0;
      } else if (char.match(/\d/)) {
        col += parseInt(char, 10);
      } else {
        const pieceType = pieceTypeFromFEN[char];
        if (pieceType) {
          pieces.push({ x: row, y: col, type: pieceType });
        }
        col++;
      }
    }

    const playingOrder = turn === "w" ? "w" : "b";

    // Parse castling options
    const castlingOptions = {
      whiteKingSide: castling.includes("K"),
      whiteQueenSide: castling.includes("Q"),
      blackKingSide: castling.includes("k"),
      blackQueenSide: castling.includes("q"),
    };

    return {
      pieces,
      playingOrder,
      castlingOptions,
    };
  };

  const boardToFEN = (): string => {
    const pieceTypeToFEN: { [key in PieceType]: string } = {
      blackKing: "k",
      blackQueen: "q",
      blackRook: "r",
      blackBishop: "b",
      blackKnight: "n",
      blackPawn: "p",
      whiteKing: "K",
      whiteQueen: "Q",
      whiteRook: "R",
      whiteBishop: "B",
      whiteKnight: "N",
      whitePawn: "P",
    };

    let fen = "";

    for (let row = 0; row < 8; row++) {
      let emptySquares = 0;

      for (let col = 0; col < 8; col++) {
        const piece = board.pieces.find((p) => p.x === row && p.y === col);

        if (piece) {
          if (emptySquares > 0) {
            fen += emptySquares.toString();
            emptySquares = 0;
          }

          fen += pieceTypeToFEN[piece.type];
        } else {
          emptySquares++;
        }
      }

      if (emptySquares > 0) {
        fen += emptySquares.toString();
      }

      if (row < 7) {
        fen += "/";
      }
    }

    fen += ` ${board.playingOrder} `;

    // Add castling options
    const castlingOptions = [
      board.castlingOptions.whiteKingSide ? "K" : "",
      board.castlingOptions.whiteQueenSide ? "Q" : "",
      board.castlingOptions.blackKingSide ? "k" : "",
      board.castlingOptions.blackQueenSide ? "q" : "",
    ];

    const castlingString = castlingOptions.filter(Boolean).join("");
    fen += castlingString.length > 0 ? castlingString : "-";

    fen += " - 0 1";

    return fen;
  };

  const handleNext = () => {
    let initialFEN = "";
    if (selectedFile) {
      setGameState(parsedJson);
      initialFEN = parsedJson.moveList[0].FEN;
    } else {
      const selectedGame = preDefinedGames.find((game) => game.gameName === selectedGameName);
      if (selectedGame) {
        setGameState(selectedGame);
        initialFEN = selectedGame.moveList[0].FEN;
      }
    }

    setBoard(FENToBoard(initialFEN));
    setGameSelectModalOpen(false);
  };

  const getStrokeWidth = () => {
    if (!boardAreaRef.current) return 0;

    return boardAreaRef.current.clientHeight * 0.02;
  };

  const getRuleColor = (color: string) => {
    if (color.toLowerCase() === "#ffffff") {
      return "black";
    }

    return color;
  };

  const handleTileClick = (x: number, y: number) => {
    const id = `${numberToChar(y)}${8 - x}`;

    if (isOptionKeyDown && isShiftKeyDown) {
      setYellowSelection(id);
    } else if (isOptionKeyDown) {
      setGreenSelection(id);
    } else if (isShiftKeyDown) {
      setRedSelection(id);
    } else if (isYKeyDown) {
      setYellowDotSelection(id);
    } else if (isGKeyDown) {
      setGreenDotSelection(id);
    } else if (isRKeyDown) {
      setRedDotSelection(id);
    }
  };

  const setYellowSelection = (id: string) => {
    if (tertiarySelectedTiles.includes(id)) {
      setTertiarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
    } else {
      setTertiarySelectedTiles((prevValues) => [...prevValues, id]);
      setSecondarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
      setPrimarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
    }
  };

  const setYellowDotSelection = (id: string) => {
    if (tertiaryDottedTiles.includes(id)) {
      setTertiaryDottedTiles((prevValues) => prevValues.filter((value) => value !== id));
    } else {
      setTertiaryDottedTiles((prevValues) => [...prevValues, id]);
    }
  };

  const setGreenDotSelection = (id: string) => {
    if (secondaryDottedTiles.includes(id)) {
      setSecondaryDottedTiles((prevValues) => prevValues.filter((value) => value !== id));
    } else {
      setSecondaryDottedTiles((prevValues) => [...prevValues, id]);
    }
  };

  const setRedDotSelection = (id: string) => {
    if (primaryDottedTiles.includes(id)) {
      setPrimaryDottedTiles((prevValues) => prevValues.filter((value) => value !== id));
    } else {
      setPrimaryDottedTiles((prevValues) => [...prevValues, id]);
    }
  };

  const setGreenSelection = (id: string) => {
    if (secondarySelectedTiles.includes(id)) {
      setSecondarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
    } else {
      setSecondarySelectedTiles((prevValues) => [...prevValues, id]);
      setTertiarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
      setPrimarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
    }
  };

  const setRedSelection = (id: string) => {
    if (primarySelectedTiles.includes(id)) {
      setPrimarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
    } else {
      setPrimarySelectedTiles((prevValues) => [...prevValues, id]);
      setSecondarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
      setTertiarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
    }
  };

  const getArabicPiece = (piece: Piece) => {
    switch (piece.type) {
      case PieceType.BKing:
        return "شاه";
      case PieceType.BQueen:
        return "فرزين";
      case PieceType.BRook:
        return "رخ";
      case PieceType.BBishop:
        return "فيل";
      case PieceType.BKnight:
        return "فرس";
      case PieceType.BPawn:
        return "بيدق";
      case PieceType.WKing:
        return "شاه";
      case PieceType.WQueen:
        return "فرزين";
      case PieceType.WRook:
        return "رخ";
      case PieceType.WBishop:
        return "فيل";
      case PieceType.WKnight:
        return "فرس";
      case PieceType.WPawn:
        return "بيدق";
    }
  };

  const getPieceImgUrl = (x: number, y: number) => {
    const piece = board.pieces.find((value: Piece) => value.x === x && value.y === y);
    if (piece) {
      const iconUrlBase = `/assets/images/pieceSets/${pieceSet}/`;

      switch (piece.type) {
        case PieceType.BKing:
          return iconUrlBase + "bK.svg";
        case PieceType.BQueen:
          return iconUrlBase + "bQ.svg";
        case PieceType.BRook:
          return iconUrlBase + "bR.svg";
        case PieceType.BBishop:
          return iconUrlBase + "bB.svg";
        case PieceType.BKnight:
          return iconUrlBase + "bN.svg";
        case PieceType.BPawn:
          return iconUrlBase + "bP.svg";
        case PieceType.WKing:
          return iconUrlBase + "wK.svg";
        case PieceType.WQueen:
          return iconUrlBase + "wQ.svg";
        case PieceType.WRook:
          return iconUrlBase + "wR.svg";
        case PieceType.WBishop:
          return iconUrlBase + "wB.svg";
        case PieceType.WKnight:
          return iconUrlBase + "wN.svg";
        case PieceType.WPawn:
          return iconUrlBase + "wP.svg";
      }
    }

    return "";
  };

  const renderBoard = () => {
    const handleDragOver = (event: any) => {
      event.preventDefault();
    };

    const handleDragStart = (event: any, x: number, y: number, pieceType: PieceType) => {
      event.dataTransfer.setData("text/plain", JSON.stringify({ x, y, pieceType }));
    };

    const handleDrop = (event: any, targetX: number, targetY: number) => {
      event.preventDefault();
    };

    const result = [];
    for (let i = 0; i < 8; i++) {
      for (let j = 0; j < 8; j++) {
        const isLightCell = (i + j) % 2 === 0;
        const piece = board.pieces.find((value: Piece) => value.x === i && value.y === j);

        const id = `${numberToChar(j)}${8 - i}`;
        result.push(
          <div
            id={id}
            key={i + "-" + j}
            className={"cell"}
            style={{
              position: "inherit",
              backgroundColor: isLightCell ? boardThemes[backgroundTheme].light : boardThemes[backgroundTheme].dark,
              border: backgroundTheme === "absoluteWhite" ? `${getStrokeWidth() * 0.05}px solid black` : "unset",
            }}
            onClick={() => handleTileClick(i, j)}
            onDragOver={(event) => {
              handleDragOver(event);
            }}
            onDrop={(event) => handleDrop(event, i, j)}
            draggable={false}
            onMouseDown={() => {
              if (isOptionKeyDown && isShiftKeyDown) {
                setTertiaryArrowStart(id);
                return;
              }

              if (isOptionKeyDown) {
                setSecondaryArrowStart(id);
              }
              if (isShiftKeyDown) {
                setPrimaryArrowStart(id);
              }
            }}
            onMouseUp={() => {
              if (isOptionKeyDown && isShiftKeyDown) {
                const arrow = tertiaryArrowStart + id;
                setTertiaryArrows((prevArrows) =>
                  prevArrows.includes(arrow) ? prevArrows.filter((value) => value !== arrow) : [...prevArrows, arrow]
                );
                setTertiaryArrowStart("");

                if (primaryArrows.includes(arrow)) {
                  setPrimaryArrows((prevArrows) => prevArrows.filter((val) => val !== arrow));
                }

                if (secondaryArrows.includes(arrow)) {
                  setSecondaryArrows((prevArrows) => prevArrows.filter((val) => val !== arrow));
                }
                return;
              }

              if (isOptionKeyDown) {
                const arrow = secondaryArrowStart + id;
                setSecondaryArrows((prevArrows) =>
                  prevArrows.includes(arrow) ? prevArrows.filter((value) => value !== arrow) : [...prevArrows, arrow]
                );
                setSecondaryArrowStart("");

                if (primaryArrows.includes(arrow)) {
                  setPrimaryArrows((prevArrows) => prevArrows.filter((val) => val !== arrow));
                }

                if (tertiaryArrows.includes(id)) {
                  setTertiaryArrows((prevArrows) => prevArrows.filter((val) => val !== arrow));
                }
              }

              if (isShiftKeyDown) {
                const arrow = primaryArrowStart + id;
                setPrimaryArrows((prevArrows) =>
                  prevArrows.includes(arrow) ? prevArrows.filter((value) => value !== arrow) : [...prevArrows, arrow]
                );
                setPrimaryArrowStart("");

                if (secondaryArrows.includes(arrow)) {
                  setSecondaryArrows((prevArrows) => prevArrows.filter((val) => val !== arrow));
                }

                if (tertiaryArrows.includes(arrow)) {
                  setTertiaryArrows((prevArrows) => prevArrows.filter((val) => val !== arrow));
                }
              }
            }}
          >
            {piece &&
              (pieceSet === "arabic" ? (
                <span
                  style={{ zIndex: 1, color: piece.type.includes("black") ? "red" : "black", fontWeight: 500, fontSize: "1.2em" }}
                  draggable={false && !isOptionKeyDown && !isShiftKeyDown}
                  onDragStart={(event) => handleDragStart(event, i, j, piece.type)}
                >
                  {getArabicPiece(piece)}
                </span>
              ) : (
                <img
                  style={{ zIndex: 1 }}
                  src={getPieceImgUrl(i, j)}
                  draggable={false && !isOptionKeyDown && !isShiftKeyDown}
                  onDragStart={(event) => handleDragStart(event, i, j, piece.type)}
                />
              ))}
            {(secondarySelectedTiles.includes(id) || primarySelectedTiles.includes(id) || tertiarySelectedTiles.includes(id)) && (
              <div
                style={{
                  boxSizing: "border-box",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  border: `${getStrokeWidth() * 0.5}px solid ${
                    secondarySelectedTiles.includes(id)
                      ? localPreferences.secondaryColor
                      : tertiarySelectedTiles.includes(id)
                      ? localPreferences.tertiaryColor
                      : localPreferences.primaryColor
                  }`,
                  zIndex: 1,
                }}
              />
            )}
            {(tertiaryDottedTiles.includes(id) || secondaryDottedTiles.includes(id) || primaryDottedTiles.includes(id)) && (
              <div
                style={{
                  boxSizing: "border-box",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  zIndex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    borderRadius: "50%",
                    background: secondaryDottedTiles.includes(id)
                      ? localPreferences.secondaryColor
                      : tertiaryDottedTiles.includes(id)
                      ? localPreferences.tertiaryColor
                      : localPreferences.primaryColor,
                    width: localPreferences.dotRange ? localPreferences.dotRange + "%" : "50%",
                    height: localPreferences.dotRange ? localPreferences.dotRange + "%" : "50%",
                  }}
                />
              </div>
            )}
          </div>
        );
      }
    }
    return result;
  };

  const getIndex = (index: number) => {
    if (index % 2 === 0) {
      return "";
    }

    return (index + 1) / 2 + ".";
  };

  const handleBackClick = () => {
    setBoard(FENToBoard(gameState.moveList[currentMoveIndex - 1].FEN));
    setCurrentMoveIndex((prevIndex) => prevIndex - 1);
  };

  const handleNextClick = () => {
    setBoard(FENToBoard(gameState.moveList[currentMoveIndex + 1].FEN));
    setCurrentMoveIndex((prevIndex) => prevIndex + 1);
  };

  const handleMoveClick = (index: number) => {
    setCurrentMoveIndex(index);
    setBoard(FENToBoard(gameState.moveList[index].FEN));
  };

  const isNextButtonDisabled = selectedFile === null && selectedGameName === "initial";
  return (
    <div>
      <div className="editor-container" tabIndex={0} ref={containerDiv}>
        <div className="left">
          <div className="left__left" />
          <div className="left__right">
            <div className="board-container">
              <div
                className="board-area"
                ref={boardAreaRef}
                style={{ borderWidth: backgroundTheme === "absoluteWhite" ? getStrokeWidth() * 0.1 : getStrokeWidth() * 0.05 }}
              >
                {renderBoard()}
                <div className="vertical-ruler" style={{ width: getStrokeWidth() + "px", fontSize: getStrokeWidth() + "px" }}>
                  {new Array(8).fill("").map((val, index) => {
                    return (
                      <span
                        className="rule"
                        style={{
                          color:
                            index % 2 === 0
                              ? getRuleColor(boardThemes[backgroundTheme].light)
                              : getRuleColor(boardThemes[backgroundTheme].dark),
                        }}
                      >
                        {8 - index}
                      </span>
                    );
                  })}
                </div>
                <div
                  className="horizontal-ruler"
                  style={{
                    height: getStrokeWidth() + "px",
                    fontSize: getStrokeWidth() + "px",
                    bottom: getStrokeWidth() / 3 + "px",
                  }}
                >
                  {new Array(8).fill("").map((val, index) => {
                    return (
                      <span
                        className="rule"
                        style={{
                          color:
                            index % 2 === 0
                              ? getRuleColor(boardThemes[backgroundTheme].light)
                              : getRuleColor(boardThemes[backgroundTheme].dark),
                        }}
                      >
                        {String.fromCharCode(65 + index)}
                      </span>
                    );
                  })}
                </div>
                {primaryArrows.map((arrow, index) => (
                  <ChessArrow
                    key={"primary-" + index}
                    start={arrow.slice(0, 2)}
                    end={arrow.slice(-2)}
                    color={localPreferences.primaryColor}
                    strokeWidth={getStrokeWidth()}
                  />
                ))}
                {secondaryArrows.map((arrow, index) => (
                  <ChessArrow
                    key={"secondary-" + index}
                    start={arrow.slice(0, 2)}
                    end={arrow.slice(-2)}
                    color={localPreferences.secondaryColor}
                    strokeWidth={getStrokeWidth()}
                  />
                ))}
                {tertiaryArrows.map((arrow, index) => (
                  <ChessArrow
                    key={"tertiary-" + index}
                    start={arrow.slice(0, 2)}
                    end={arrow.slice(-2)}
                    color={localPreferences.tertiaryColor}
                    strokeWidth={getStrokeWidth()}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="right" style={{ gap: 0 }}>
          <div className="menu-container" style={{ gap: 0 }}>
            <div className="movement-container">
              <div className="title">Move List</div>
              <div className="list">
                {gameState?.moveList?.map((move: any, index: number) => (
                  <div
                    className="item"
                    style={{
                      flexBasis: index === 0 ? "100%" : "45%",
                      background: index === currentMoveIndex ? "#404040" : "unset",
                    }}
                    onClick={() => handleMoveClick(index)}
                  >
                    {index > 0 && getIndex(index)}
                    {move.move}
                  </div>
                ))}
              </div>
            </div>
            <div className="action-btn-list"></div>
          </div>
          <div className="flex flex-row gap-3 mt-3">
            <Button variant="outlined" onClick={handleBackClick} disabled={currentMoveIndex === 0}>
              {"<"}
            </Button>
            <Button variant="outlined" onClick={handleNextClick} disabled={currentMoveIndex === gameState?.moveList?.length - 1}>
              {">"}
            </Button>
          </div>
          {gameState?.result && <div className="mt-3">Game result: {gameState.result}</div>}
        </div>
      </div>
      <Modal open={gameSelectModalOpen} onClose={() => {}}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2" color="whitesmoke">
            Game Select
          </Typography>
          <div className="mt-5 flex flex-row gap-3">
            <Select
              id="game-select"
              value={selectedGameName}
              fullWidth
              onChange={(event) => setSelectedGameName(event.target.value)}
              placeholder="Game Select"
              disabled={!!selectedFile}
            >
              <MenuItem value="initial">Select Game</MenuItem>
              {preDefinedGames.map((game) => (
                <MenuItem key={game.gameName} value={game.gameName}>
                  {game.gameName}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="mt-3 flex justify-center" style={{ color: "white" }}>
            - Or -
          </div>
          <div className="mt-3 flex flex-row gap-3" style={{ color: "white" }}>
            <input type="file" accept=".json" onChange={handleFileChange} />
          </div>
          <div className="mt-3 flex justify-end">
            <Button variant="outlined" onClick={handleNext} disabled={isNextButtonDisabled}>
              Next
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SavedGames;
