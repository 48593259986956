import { Alert, Button, MenuItem, Select, SelectChangeEvent, Snackbar, TextField } from "@mui/material";
import "./scss/HomeworkGenerator.scss";
import { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { BorderStyle, Document, ImageRun, Packer, Paragraph, Style, Table, TableCell, TableRow, TextRun, WidthType } from "docx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const HomeworkGenerator = () => {
  const [subject, setSubject] = useState<string>("");
  const [preparer, setPreparer] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [outputType, setOutputType] = useState<"pdf" | "docx">("docx");
  const [image, setImage] = useState<File | null>(null);
  const [imageHeight, setImageHeight] = useState<number>(0);
  const [imageWidth, setImageWidth] = useState<number>(0);

  const chessTopics = [
    "1. Chess Board Geometry",
    "2. Chess Pieces and Starting Setup",
    "3. Rook",
    "4. Fil (Elephant)",
    "5. Vizier (Queen)",
    "6. Horse",
    "7. Pawn",
    "8. Shah (King)",
    "9. Teamwork - Common Squares",
    "10. Defense - Attack",
    "11. Check from a safe square",
    "12. Getting out of check - I - Take, Block, Escape",
    "13. Getting out of check - II - Mixed",
    "14. Definition of mate - Elementary mate names",
    "15. Aided queen mate",
    "16. Sample mates II - Controlling the escape squares of the king",
    "17. Castling",
    "18. Defense Techniques - Protection",
    "19. Exchanges",
    "20. Draw - Stalemate, unsufficient material, and three-fold repetition",
    "21. Attack with two pieces",
    "22. Notation",
    "23. Pawn Practice - special moves",
    "24. Shepherd's mate",
    "25. Defense Techniques - Escape",
    "26. Defense Techniques - Capture",
    "27. Defense Techniques - Block (Interpose)",
    "28. Defense Techniques - Pin",
    "29. Preventing activity of your opponent",
    "30. Double Attack: Vizier (S + M)",
    "31. Double Attack: Vizier (M+M)",
    "32. Double Attack: Vizier (Mixed)",
    "33. Pin (M+K)",
    "34. Pin 2 (M+K)",
    "35. Double Attack: Knight (S+M)",
    "36. Double Attack: Knight (M+M)",
    "37. Double Attack: Knight (mixed)",
    "38. Eliminating the defender: capturing",
    "39. Eliminating the defender: chasing away",
    "40. Eliminating the defender: diverting",
    "41. Eliminating the defender: diverting through overload",
    "42. Golden Rules: Badly developed",
    "43. Golden Rules: Best development move",
    "44. Mate in two",
    "45. Mate in two: 2",
    "46. Target is a Square: Double Attack (M+S)",
    "47. Target is a Square: Pin (M+S)",
    "48. Mate with rook",
    "49. Double Attack: Rook, Fil, Shah, Pawn (1)",
    "50. Double Attack: Rook, Fil, Shah, Pawn (2)",
    "51. Pawn Endings",
    "52. Double attack: 2 pieces",
    "53. Discovered Attack: Shah + Material",
    "54. Discovered Attack: M + M",
    "55. Discovered attack: Material + Square",
    "56. Defending against mate: protecting",
    "57. Defending against mate: Escape",
    "58. Defending against mate: Capture",
    "59. Defending against mate: Interposing",
    "60. Discovered check",
    "61. Attacking a pinned piece: 1",
    "62. Attacking a pinned piece: 2",
    "63. Defend against double attack",
    "64. Defend against pin",
  ];

  useEffect(() => {
    if (image) {
      // setDescription("");
      const reader = new FileReader();
      reader.readAsArrayBuffer(image);

      reader.onloadend = async () => {
        if (reader.result) {
          const arrayBuffer = reader.result as ArrayBuffer;

          const blob = new Blob([arrayBuffer]);
          const url = URL.createObjectURL(blob);
          const img = new Image();

          img.src = url;

          let width;
          let height;
          // Wait for the image to load
          img.onload = async () => {
            setImageHeight(img.height);
            setImageWidth(img.width);
          };
        }
      };
    }
  }, [image]);

  const handleOutputTypeChange = (event: SelectChangeEvent) => {
    setOutputType(event.target.value as "pdf" | "docx");
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file && file.type.startsWith("image/")) {
      setImage(file);
    } else {
      alert("Please select an image file.");
    }
  };

  const isFormValid = () => {
    if (subject.length === 0) {
      alert("Konu alanını doldurunuz");
      return false;
    } else if (preparer.length === 0) {
      alert("Hazırlayan alanını doldurunuz");
      return false;
    } else if (image === null) {
      alert("Lütfen ödev resmi seçiniz");
      return false;
    }

    return true;
  };

  const getDescriptionForPrint = () => {
    const currentRowCount = calculateRows(description);
    const maxRowCount = getMaxRowCount();

    let diffLine = "";

    const rowCountDifference = maxRowCount - currentRowCount;

    for (let i = 0; i < rowCountDifference; i++) {
      diffLine += "\n";
    }

    const lines = description + diffLine;
    const textRuns = lines.split("\n").map((line) => new TextRun({ break: 1, text: line }));
    const paragraph = new Paragraph({ children: textRuns, style: "description" });

    return paragraph;
  };

  const handleSubmit = () => {
    if (!isFormValid()) {
      return;
    }

    generateDocument();
  };

  const generateDocument = () => {
    if (outputType === "pdf") {
      return generatePDF();
    } else if (outputType === "docx") {
      return generateDOCX();
    } else {
      throw new Error("Invalid output type");
    }
  };

  function getDataUri(url: string) {
    return new Promise((resolve) => {
      var image = new Image();
      image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain

      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = (this as any).naturalWidth;
        canvas.height = (this as any).naturalHeight;

        //next three lines for white background in case png has a transparent background
        var ctx = canvas.getContext("2d");
        if (!ctx) return;
        ctx.fillStyle = "#fff"; /// set white fill style
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        if (!canvas) return;
        canvas.getContext("2d")?.drawImage(this as any, 0, 0);

        resolve(canvas.toDataURL("image/jpeg"));
      };

      image.src = url;
    });
  }

  const generateDOCX = async () => {
    if (!image) {
      return;
    }

    const cellBorderStyle = {
      top: { style: BorderStyle.THICK, size: 5 },
      bottom: { style: BorderStyle.THICK, size: 5 },
      left: { style: BorderStyle.THICK, size: 5 },
      right: { style: BorderStyle.THICK, size: 5 },
    };

    const reader = new FileReader();
    reader.readAsArrayBuffer(image);

    reader.onloadend = async () => {
      if (reader.result) {
        const arrayBuffer = reader.result as ArrayBuffer;

        const blob = new Blob([arrayBuffer]);
        const url = URL.createObjectURL(blob);
        const img = new Image();

        img.src = url;

        let width;
        let height;
        // Wait for the image to load
        img.onload = async () => {
          width = imageWidth;
          height = imageHeight;

          // Release the object URL
          URL.revokeObjectURL(url);

          const ds_logo_blob = (await fetch("/assets/images/hwDocImgs/ds_logo.png").then((r) => r.blob())) as any;
          const octopus_1_blob = (await fetch("/assets/images/hwDocImgs/octopus_1.png").then((r) => r.blob())) as any;
          const octopus_2_blob = (await fetch("/assets/images/hwDocImgs/octopus_2.png").then((r) => r.blob())) as any;

          const doc = new Document({
            styles: {
              paragraphStyles: [
                {
                  id: "description",
                  name: "description",
                  basedOn: "Normal",
                  next: "Normal",
                  quickFormat: true,
                  run: {
                    font: "ComicSansMS",
                  },
                },
                {
                  id: "bold",
                  name: "bold",
                  basedOn: "Normal",
                  next: "Normal",
                  quickFormat: true,
                  run: {
                    bold: true,
                  },
                },
              ],
            },
            sections: [
              {
                properties: {
                  page: {
                    margin: {
                      top: 200,
                      right: 200,
                      bottom: 200,
                      left: 200,
                    },
                  },
                },
                children: [
                  new Table({
                    width: {
                      size: 100,
                      type: WidthType.PERCENTAGE,
                    },
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            width: { size: 30, type: "pct" },
                            children: [
                              new Paragraph({
                                children: [
                                  new ImageRun({
                                    data: ds_logo_blob,
                                    transformation: {
                                      width: 221,
                                      height: 91,
                                    },
                                  }),
                                ],
                              }),
                            ],
                            borders: cellBorderStyle,
                            verticalAlign: "center",
                          }),
                          new TableCell({
                            width: { size: 15, type: "pct" },
                            children: [
                              new Paragraph({
                                children: [
                                  new ImageRun({
                                    data: octopus_1_blob,
                                    floating: { horizontalPosition: { align: "center" }, verticalPosition: { align: "center" } },
                                    transformation: {
                                      width: 115,
                                      height: 120,
                                    },
                                  }),
                                ],
                              }),
                            ],
                            verticalAlign: "center",
                            borders: cellBorderStyle,
                          }),
                          new TableCell({
                            width: { size: 15, type: "pct" },
                            children: [
                              new Paragraph({
                                children: [
                                  new ImageRun({
                                    data: octopus_2_blob,
                                    transformation: {
                                      width: 130,
                                      height: 140,
                                    },
                                  }),
                                ],
                              }),
                            ],
                            verticalAlign: "center",
                            borders: cellBorderStyle,
                          }),
                          new TableCell({
                            width: { size: 30, type: "pct" },
                            borders: cellBorderStyle,
                            children: [
                              new Paragraph({ text: "İsim:", style: "bold" }),
                              new Paragraph(""),
                              new Paragraph(""),
                              new Paragraph({ text: "Okul/Sınıf:", style: "bold" }),
                              new Paragraph(""),
                              new Paragraph(""),
                              new Paragraph({ text: "Tarih:", style: "bold" }),
                            ],
                            verticalAlign: "center",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new Table({
                    width: {
                      size: 100,
                      type: WidthType.AUTO,
                    },
                    columnWidths: [20, 200, 200],
                    layout: "fixed",
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            verticalAlign: "center",
                            children: [new Paragraph({ text: "KONU", alignment: "center", style: "bold" })],
                          }),
                          new TableCell({
                            verticalAlign: "center",
                            children: subject.split("\n").map((val) => new Paragraph({ text: val, alignment: "center" })),
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({ text: "Hazırlayan:", alignment: "center", style: "bold" }),
                              new Paragraph({ text: preparer, alignment: "center" }),
                            ],
                          }),
                        ],
                      }),
                    ],
                  }),
                  new Paragraph(""),
                  getDescriptionForPrint(),
                  new Paragraph(""),
                  new Paragraph({
                    alignment: "center",
                    children: [
                      new ImageRun({
                        data: arrayBuffer,
                        transformation: {
                          width: 760,
                          height: (760 * imageHeight) / imageWidth,
                        },
                      }),
                    ],
                  }),
                ],
              },
            ],
          });

          Packer.toBlob(doc).then((blob) => {
            saveAs(blob, `${subject}_${preparer}.docx`);
          });
        };
      } else {
        console.error("Error reading file:", reader.error);
      }
    };
  };

  const generatePDF = async () => {
    if (!image) {
      return;
    }

    try {
      const doc = new jsPDF();
      const padding = 10;

      var logo = await getDataUri("/assets/images/hwDocImgs/ds_logo.png");
      doc.addImage(logo as any, "PNG", padding, padding + 5, 50, 20);

      doc.rect(padding, padding, 50, 30);

      var logo = await getDataUri("/assets/images/hwDocImgs/octopus_1.png");
      doc.addImage(logo as any, "PNG", 61, 12, 27, 27);

      doc.rect(60, 10, 30, 30);

      var logo = await getDataUri("/assets/images/hwDocImgs/octopus_2.png");
      doc.addImage(logo as any, "PNG", 90, 10, 30, 30);

      doc.rect(90, 10, 30, 30);
      doc.rect(120, 10, 70, 30);

      doc.setFontSize(9);
      doc.text("Isim:", 123, 16);
      doc.text("Okul/Sinif:", 123, 26);
      doc.text("Tarih:", 123, 36);

      doc.rect(10, 40, 20, 12);
      doc.rect(30, 40, 90, 12);
      doc.rect(120, 40, 70, 12);

      doc.setFontSize(10);
      doc.text("KONU", 14, 47);
      doc.text(subject, 44, 47); // @todo: başlangıç pozisyonunu text'e göre ayarla
      doc.text("Hazirlayan:", 140, 45); // @todo: başlangıç pozisyonunu text'e göre ayarla
      doc.text(preparer, 140, 49); // @todo: başlangıç pozisyonunu text'e göre ayarla

      var splitTitle = doc.splitTextToSize(description, 180);
      doc.text(splitTitle, 10, 60);

      const imageData: any = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target?.result as string);
        reader.readAsDataURL(image);
      });

      // Add the image to the PDF, conditionally
      if (image) {
        doc.addImage(imageData, "PNG", 10, 80, 175, 175);
      }

      doc.save(`${subject}_${preparer}.pdf`);
    } catch (error) {
      console.error("Error creating PDF:", error);
      // Handle errors appropriately, e.g., display user-friendly messages
    }
  };

  function calculateRows(text: string) {
    // Create a hidden div to measure the text
    const div = document.createElement("div");
    div.style.visibility = "hidden";
    div.style.position = "absolute";
    div.style.width = "920px";
    div.style.fontFamily = "ComicSansMS";
    div.style.fontSize = "16px";
    div.style.lineHeight = "30px";
    div.textContent = text;

    // Append to document body to measure
    document.body.appendChild(div);

    // Calculate the number of rows
    const height = div.offsetHeight;
    const rows = Math.ceil(height / 30);

    // Remove the div from the body
    document.body.removeChild(div);

    return rows;
  }

  const getMaxRowCount = () => {
    if (imageHeight && imageWidth) {
      const resizedHeight = (imageHeight * 760) / imageWidth;

      const calculated = Math.ceil((860 - resizedHeight) / 18.6);

      return calculated > 1 ? calculated - 1 : calculated;
    }

    return 1;
  };

  const handleChange = (e: any) => {
    const newDescription = e.target.value;
    const rowCount = calculateRows(newDescription);

    if (rowCount > getMaxRowCount()) {
      handleClick();
      return;
    }

    setDescription(newDescription);
  };

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div className="hw-generator">
      <div>
        <b>Chess Homework Document Generator</b>
      </div>
      <div className="hw-generator__input-row">
        <div className="hw-generator__input-row__label">Select</div>
        <div className="hw-generator__input-row__input">
          <Select>
            {chessTopics.map((val, index) => (
              <MenuItem key={index} onClick={() => setSubject(val)}>
                {val}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className="hw-generator__input-row">
        <div className="hw-generator__input-row__label">Subject</div>
        <div className="hw-generator__input-row__input">
          <TextField multiline rows={2} maxRows={2} fullWidth value={subject} onChange={(e) => setSubject(e.target.value)} />
        </div>
      </div>
      <div className="hw-generator__input-row">
        <div className="hw-generator__input-row__label">Preparer</div>
        <div className="hw-generator__input-row__input">
          <TextField fullWidth value={preparer} onChange={(e) => setPreparer(e.target.value)} />
        </div>
      </div>
      <div className="hw-generator__input-row">
        <div className="hw-generator__input-row__label">Image</div>
        <div className="hw-generator__input-row__input">
          <input type="file" accept="image/*" onChange={handleImageChange} />
        </div>
      </div>
      <div className="hw-generator__input-row">
        <div className="hw-generator__input-row__label">
          Description
          {!!image && (
            <div style={{ fontSize: "0.8em" }}>
              Row Count: {calculateRows(description)}/{getMaxRowCount()}
            </div>
          )}
        </div>
        <div className="hw-generator__input-row__input">
          <TextField
            fullWidth
            multiline
            rows={12}
            value={description}
            style={{ fontFamily: "ComicSansMS" }}
            inputProps={{ style: { fontFamily: "ComicSansMS" } }}
            onChange={handleChange}
            disabled={!Boolean(image)}
            placeholder={!Boolean(image) ? "Please select image before description" : ""}
          />
        </div>
      </div>
      <div className="hw-generator__input-row">
        <div className="hw-generator__input-row__submit">
          <Button variant="contained" onClick={handleSubmit}>
            Generate
          </Button>
        </div>
      </div>
      {/* <div style={{ color: "white", fontFamily: "ComicSansMS", width: "920px" }}>{description}</div> */}
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" variant="filled" sx={{ width: "100%" }}>
          You can't enter more characters
        </Alert>
      </Snackbar>
    </div>
  );
};

export default HomeworkGenerator;
