import { useEffect, useRef, useState } from "react";
import { SelectionState } from "./types/SelectionStates";
import SelectionBtn from "./components/SelectionBtn/SelectionBtn";
import { Board, Piece, PieceType } from "./types/Board";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { emptyBoardFEN, initialFEN, preSettedGames } from "./constants";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/types";
import { boardThemes } from "../../constants";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import { useParams } from "react-router-dom";
import { numberToChar } from "../../utils/numberToChar";
import { toSvg, toPng, toJpeg } from "html-to-image";
import GetAppIcon from "@mui/icons-material/GetApp";
import DrawingInfoDisplayer from "../../components/DrawingInfoDisplayer/DrawingInfoDisplayer";
import ChessArrow from "../../components/ChessArrow/ChessArrow";
import "./scss/editor.scss";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";
const validateFEN = require("fen-validator").default;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 20,
};

const Editor = () => {
  const { defaultFen } = useParams();

  const { pieceSet, backgroundTheme, analysisPreferences } = useSelector((state: RootState) => state.board);

  const localPreferences = analysisPreferences ?? {
    primaryColor: "#ff0000",
    secondaryColor: "#00ff00",
    tertiaryColor: "#ffff00",
    dotRange: 50,
  };

  const containerDiv = useRef<HTMLDivElement>(null);
  const boardAreaRef = useRef<HTMLDivElement>(null);

  const [currentSelectionState, setSelectionState] = useState<SelectionState>(SelectionState.FreeSelection);

  const [moveList, setMoveList] = useState<any[]>([]);
  const [selectedPath, setSelectedPath] = useState<string>();

  const [mode, setMode] = useState<"editor" | "game">("editor");
  const toggleMode = () => setMode((prevValue) => (prevValue === "editor" ? "game" : "editor"));

  const [currentFEN, setCurrentFEN] = useState<string>(initialFEN);
  const [board, setBoard] = useState<Board>({
    pieces: [],
    playingOrder: "w",

    castlingOptions: {
      whiteKingSide: false,
      whiteQueenSide: false,
      blackKingSide: false,
      blackQueenSide: false,
    },
  });

  const [selectedGame, setSelectedGame] = useState<string>("-1");
  const isFENSetted = useRef<boolean>(false);

  const lastMove = useRef<any>(undefined);

  const isTurnedBack = useRef<boolean>(false);

  const [isOptionKeyDown, setIsOptionKeyDown] = useState<boolean>(false);
  const [isShiftKeyDown, setIsShiftKeyDown] = useState<boolean>(false);
  const [isGKeyDown, setIsGKeyDown] = useState<boolean>(false);
  const [isRKeyDown, setIsRKeyDown] = useState<boolean>(false);
  const [isYKeyDown, setIsYKeyDown] = useState<boolean>(false);

  const [primarySelectedTiles, setPrimarySelectedTiles] = useState<string[]>([]);
  const [primaryDottedTiles, setPrimaryDottedTiles] = useState<string[]>([]);
  const [primaryArrowStart, setPrimaryArrowStart] = useState<string>("");
  const [primaryArrows, setPrimaryArrows] = useState<string[]>([]);

  const [secondarySelectedTiles, setSecondarySelectedTiles] = useState<string[]>([]);
  const [secondaryDottedTiles, setSecondaryDottedTiles] = useState<string[]>([]);
  const [secondaryArrowStart, setSecondaryArrowStart] = useState<string>("");
  const [secondaryArrows, setSecondaryArrows] = useState<string[]>([]);

  const [tertiarySelectedTiles, setTertiarySelectedTiles] = useState<string[]>([]);
  const [tertiaryDottedTiles, setTertiaryDottedTiles] = useState<string[]>([]);
  const [tertiaryArrowStart, setTertiaryArrowStart] = useState<string>("");
  const [tertiaryArrows, setTertiaryArrows] = useState<string[]>([]);

  const [isExportModalOpen, setExportModalOpen] = useState<boolean>(false);
  const [isGameExportOpen, setGameExportOpen] = useState<boolean>(false);
  const [gameExportName, setGameExportName] = useState<string>("");
  const [gameDesc, setGameDesc] = useState<string>("");
  const [gameName, setGameName] = useState<string>("chess");
  const [gameVariant, setGameVariant] = useState<string>("standard");
  const [questionNo, setQuestionNo] = useState<string>("");
  const [book, setBook] = useState<string>("");
  const [pageCell, setPageCell] = useState<string>("");
  const [event, setEvent] = useState<string>("");
  const [site, setSite] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [white, setWhite] = useState<string>("");
  const [black, setBlack] = useState<string>("");
  const [eco, setEco] = useState<string>("");
  const [round, setRound] = useState<string>("");
  const [timeControl, setTimeControl] = useState<string>("");
  const [whiteClock, setWhiteClock] = useState<string>("");
  const [blackClock, setBlackClock] = useState<string>("");
  const [clock, setClock] = useState<string>("");

  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [renderHelper, setRenderHelper] = useState<boolean>(false);

  const [gameWinner, setGameWinner] = useState<string>();
  const [isGameEnded, setGameEnded] = useState<boolean>(false);

  const [pawnPromotion, setPawnPromotion] = useState<any>(undefined);
  const isPawnPromotionDetected = useRef<boolean>(false);
  const isCastlingClicked = useRef<boolean>(false);

  const handleResize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClose = () => {
    setExportModalOpen(false);
  };

  const handleKeyDown = (e: any) => {
    if (mode === "game") {
      return;
    }

    if (e.key === "Backspace" || e.key === "Delete") {
      clearDrawings();
    }

    if (e.key === "s") {
      setIsGKeyDown(true);
    }

    if (e.key === "p") {
      setIsRKeyDown(true);
    }

    if (e.key === "t") {
      setIsYKeyDown(true);
    }

    if (e.key === "Alt") {
      setIsOptionKeyDown(true);
    }

    if (e.key === "Shift") {
      setIsShiftKeyDown(true);
    }
  };

  const handleKeyUp = (e: any) => {
    // Reset the state when the option key is released
    if (e.key === "s") {
      setIsGKeyDown(false);
    }

    if (e.key === "p") {
      setIsRKeyDown(false);
    }

    if (e.key === "t") {
      setIsYKeyDown(false);
    }

    if (e.key === "Alt") {
      setIsOptionKeyDown(false);
    }
    if (e.key === "Shift") {
      setIsShiftKeyDown(false);
    }
  };

  useEffect(() => {
    containerDiv.current?.focus();
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    if (defaultFen) {
      if (validateFEN(defaultFen)) {
        setCurrentFEN(defaultFen);
      } else {
        alert("Invalid FEN Representation");
      }
    }
  }, []);

  useEffect(() => {
    if (validateFEN(currentFEN)) {
      setBoard(FENToBoard(currentFEN));
      isFENSetted.current = true;
    }
  }, [currentFEN]);

  useEffect(() => {
    if (!isFENSetted.current) {
      setCurrentFEN(boardToFEN());
    }
    isFENSetted.current = false;
  }, [board]);

  useEffect(() => {
    if (selectedGame.length > 0 && selectedGame !== "-1") {
      setCurrentFEN(selectedGame);
    }
  }, [selectedGame]);

  useEffect(() => {
    if (mode === "game" && isTurnedBack.current !== true) {
      if (isPawnPromotionDetected.current) {
        isPawnPromotionDetected.current = false;
        return;
      }

      setMoveList((prevValue) => {
        if (prevValue.length === 1 && lastMove?.current?.color === "b") {
          return [
            ...prevValue,
            { move: "...", FEN: currentFEN, children: [], description: "" },
            { move: lastMove?.current?.move, FEN: currentFEN, children: [], description: "" },
          ];
        }

        const move = {
          move: lastMove.current?.move,
          color: lastMove.current?.color,
          FEN: currentFEN,
          children: [],
          description: "",
        };
        const pathList = selectedPath?.split("-").map((val) => parseInt(val));
        if (pathList?.length === 1) {
          prevValue[pathList[0]].children.push(move);
          return prevValue;
        } else if (pathList?.length === 2) {
          prevValue[pathList[0]].children[pathList[1]].children.push(move);
          return prevValue;
        } else if (pathList?.length === 3) {
        }

        return [...prevValue, move];
      });
    }
  }, [currentFEN]);

  const handleExport = (exportType: "jpg" | "svg") => {
    if (!boardAreaRef.current) return;

    let exporter = exportType === "svg" ? toSvg : toJpeg;

    let exportOptions: any = {};
    if (exportType === "jpg") {
      exportOptions.quality = 1;
    }

    // Temporarily adjust styles for export
    const originalStyle = boardAreaRef.current.style.cssText;
    boardAreaRef.current.style.width = "3200px";
    boardAreaRef.current.style.height = "3200px";

    setRenderHelper((prevVal) => !prevVal);
    setTimeout(() => {
      if (!boardAreaRef.current) return;

      exporter(boardAreaRef.current, exportOptions)
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `chess-board.${exportType}`;
          link.href = dataUrl;
          link.click();

          if (!boardAreaRef.current) return;
          // Revert to original styles after export
          boardAreaRef.current.style.cssText = originalStyle;
          setExportModalOpen(false);
        })
        .catch((err) => {
          console.log(err);

          if (!boardAreaRef.current) return;
          // Revert to original styles in case of error
          boardAreaRef.current.style.cssText = originalStyle;
        });
    }, 100);
  };

  const convertToChessPosition = (x: number, y: number) => {
    const letters = ["a", "b", "c", "d", "e", "f", "g", "h"];

    if (x < 0 || x > 7 || y < 0 || y > 7) {
      return "Invalid coordinates";
    }

    const file = letters[y];
    const rank = 8 - x;

    return file + rank;
  };

  const FENToBoard = (fen: string): Board => {
    const fenParts = fen.split(" ");

    if (fenParts.length !== 6) {
      throw new Error("Invalid FEN string");
    }

    const pieceTypeFromFEN: { [key: string]: PieceType } = {
      k: PieceType.BKing,
      q: PieceType.BQueen,
      r: PieceType.BRook,
      b: PieceType.BBishop,
      n: PieceType.BKnight,
      p: PieceType.BPawn,
      K: PieceType.WKing,
      Q: PieceType.WQueen,
      R: PieceType.WRook,
      B: PieceType.WBishop,
      N: PieceType.WKnight,
      P: PieceType.WPawn,
    };

    const [piecePlacement, turn, castling, enPassant, halfMove, fullMove] = fenParts;

    const pieces: Piece[] = [];
    let row = 0;
    let col = 0;

    for (const char of piecePlacement) {
      if (char === "/") {
        row++;
        col = 0;
      } else if (char.match(/\d/)) {
        col += parseInt(char, 10);
      } else {
        const pieceType = pieceTypeFromFEN[char];
        if (pieceType) {
          pieces.push({ x: row, y: col, type: pieceType });
        }
        col++;
      }
    }

    const playingOrder = turn === "w" ? "w" : "b";

    // Parse castling options
    const castlingOptions = {
      whiteKingSide: castling.includes("K"),
      whiteQueenSide: castling.includes("Q"),
      blackKingSide: castling.includes("k"),
      blackQueenSide: castling.includes("q"),
    };

    return {
      pieces,
      playingOrder,
      castlingOptions,
    };
  };

  const boardToFEN = (): string => {
    const pieceTypeToFEN: { [key in PieceType]: string } = {
      blackKing: "k",
      blackQueen: "q",
      blackRook: "r",
      blackBishop: "b",
      blackKnight: "n",
      blackPawn: "p",
      whiteKing: "K",
      whiteQueen: "Q",
      whiteRook: "R",
      whiteBishop: "B",
      whiteKnight: "N",
      whitePawn: "P",
    };

    let fen = "";

    for (let row = 0; row < 8; row++) {
      let emptySquares = 0;

      for (let col = 0; col < 8; col++) {
        const piece = board.pieces.find((p) => p.x === row && p.y === col);

        if (piece) {
          if (emptySquares > 0) {
            fen += emptySquares.toString();
            emptySquares = 0;
          }

          fen += pieceTypeToFEN[piece.type];
        } else {
          emptySquares++;
        }
      }

      if (emptySquares > 0) {
        fen += emptySquares.toString();
      }

      if (row < 7) {
        fen += "/";
      }
    }

    fen += ` ${board.playingOrder} `;

    // Add castling options
    const castlingOptions = [
      board.castlingOptions.whiteKingSide ? "K" : "",
      board.castlingOptions.whiteQueenSide ? "Q" : "",
      board.castlingOptions.blackKingSide ? "k" : "",
      board.castlingOptions.blackQueenSide ? "q" : "",
    ];

    const castlingString = castlingOptions.filter(Boolean).join("");
    fen += castlingString.length > 0 ? castlingString : "-";

    fen += " - 0 1";

    return fen;
  };

  const setYellowSelection = (id: string) => {
    if (tertiarySelectedTiles.includes(id)) {
      setTertiarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
    } else {
      setTertiarySelectedTiles((prevValues) => [...prevValues, id]);
      setSecondarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
      setPrimarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
    }
  };

  const setYellowDotSelection = (id: string) => {
    if (tertiaryDottedTiles.includes(id)) {
      setTertiaryDottedTiles((prevValues) => prevValues.filter((value) => value !== id));
    } else {
      setTertiaryDottedTiles((prevValues) => [...prevValues, id]);
    }
  };

  const setGreenDotSelection = (id: string) => {
    if (secondaryDottedTiles.includes(id)) {
      setSecondaryDottedTiles((prevValues) => prevValues.filter((value) => value !== id));
    } else {
      setSecondaryDottedTiles((prevValues) => [...prevValues, id]);
    }
  };

  const setRedDotSelection = (id: string) => {
    if (primaryDottedTiles.includes(id)) {
      setPrimaryDottedTiles((prevValues) => prevValues.filter((value) => value !== id));
    } else {
      setPrimaryDottedTiles((prevValues) => [...prevValues, id]);
    }
  };

  const setGreenSelection = (id: string) => {
    if (secondarySelectedTiles.includes(id)) {
      setSecondarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
    } else {
      setSecondarySelectedTiles((prevValues) => [...prevValues, id]);
      setTertiarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
      setPrimarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
    }
  };

  const setRedSelection = (id: string) => {
    if (primarySelectedTiles.includes(id)) {
      setPrimarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
    } else {
      setPrimarySelectedTiles((prevValues) => [...prevValues, id]);
      setSecondarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
      setTertiarySelectedTiles((prevValues) => prevValues.filter((value) => value !== id));
    }
  };

  const handleTileClick = (x: number, y: number) => {
    const id = `${numberToChar(y)}${8 - x}`;

    if (isOptionKeyDown && isShiftKeyDown) {
      setYellowSelection(id);
    } else if (isOptionKeyDown) {
      setGreenSelection(id);
    } else if (isShiftKeyDown) {
      setRedSelection(id);
    } else if (isYKeyDown) {
      setYellowDotSelection(id);
    } else if (isGKeyDown) {
      setGreenDotSelection(id);
    } else if (isRKeyDown) {
      setRedDotSelection(id);
    }

    if (currentSelectionState === SelectionState.FreeSelection) {
    } else if (currentSelectionState === SelectionState.FreeRemove) {
      setBoard((prevBoard) => {
        return {
          ...prevBoard,
          pieces: prevBoard.pieces.filter((value) => !(value.x === x && value.y === y)),
        };
      });
    } else {
      setBoard((prevBoard) => {
        const updatedPieces = [...prevBoard.pieces];
        const existingPieceIndex = updatedPieces.findIndex((piece) => piece.x === x && piece.y === y);

        if (existingPieceIndex !== -1) {
          // If there is already a piece at the selected tile, replace it
          updatedPieces[existingPieceIndex] = {
            x,
            y,
            type: Object.values(PieceType).find((enumValue) => enumValue === currentSelectionState.toString()) as PieceType,
          };
        } else {
          // If there is no piece at the selected tile, add a new one
          updatedPieces.push({
            x,
            y,
            type: Object.values(PieceType).find((enumValue) => enumValue === currentSelectionState.toString()) as PieceType,
          });
        }

        return { ...prevBoard, pieces: updatedPieces };
      });
    }
  };

  const getPieceImgUrl = (x: number, y: number) => {
    const piece = board.pieces.find((value: Piece) => value.x === x && value.y === y);
    if (piece) {
      const iconUrlBase = `/assets/images/pieceSets/${pieceSet}/`;

      switch (piece.type) {
        case PieceType.BKing:
          return iconUrlBase + "bK.svg";
        case PieceType.BQueen:
          return iconUrlBase + "bQ.svg";
        case PieceType.BRook:
          return iconUrlBase + "bR.svg";
        case PieceType.BBishop:
          return iconUrlBase + "bB.svg";
        case PieceType.BKnight:
          return iconUrlBase + "bN.svg";
        case PieceType.BPawn:
          return iconUrlBase + "bP.svg";
        case PieceType.WKing:
          return iconUrlBase + "wK.svg";
        case PieceType.WQueen:
          return iconUrlBase + "wQ.svg";
        case PieceType.WRook:
          return iconUrlBase + "wR.svg";
        case PieceType.WBishop:
          return iconUrlBase + "wB.svg";
        case PieceType.WKnight:
          return iconUrlBase + "wN.svg";
        case PieceType.WPawn:
          return iconUrlBase + "wP.svg";
      }
    }

    return "";
  };

  const getArabicPiece = (piece: Piece) => {
    switch (piece.type) {
      case PieceType.BKing:
        return "شاه";
      case PieceType.BQueen:
        return "فرزين";
      case PieceType.BRook:
        return "رخ";
      case PieceType.BBishop:
        return "فيل";
      case PieceType.BKnight:
        return "فرس";
      case PieceType.BPawn:
        return "بيدق";
      case PieceType.WKing:
        return "شاه";
      case PieceType.WQueen:
        return "فرزين";
      case PieceType.WRook:
        return "رخ";
      case PieceType.WBishop:
        return "فيل";
      case PieceType.WKnight:
        return "فرس";
      case PieceType.WPawn:
        return "بيدق";
    }
  };

  const renderBoard = () => {
    const handleDragOver = (event: any) => {
      event.preventDefault();
    };

    const handleDragStart = (event: any, x: number, y: number, pieceType: PieceType) => {
      event.dataTransfer.setData("text/plain", JSON.stringify({ x, y, pieceType }));
    };

    const handleDrop = (event: any, targetX: number, targetY: number) => {
      if (isGameEnded) return;

      event.preventDefault();

      const data = event.dataTransfer.getData("text/plain");
      const { x, y, pieceType } = JSON.parse(data);

      const isSameMove = targetX === x && targetY === y;
      if (isSameMove) {
        return;
      }

      if (mode === "game") {
        for (let i = 0; i < board.pieces.length; i++) {
          if (board.pieces[i].x === targetX && board.pieces[i].y === targetY) {
            if (board.pieces[i].type.slice(0, 3) === pieceType.slice(0, 3)) {
              return;
            }
          }
        }

        lastMove.current = {
          color: pieceType.includes("white") ? "w" : "b",
          move: convertToChessPosition(x, y) + convertToChessPosition(targetX, targetY),
        };
        isTurnedBack.current = false;
      }

      if (mode === "game") {
        if (targetX === 0 && pieceType === PieceType.WPawn) {
          isPawnPromotionDetected.current = true;
          setPawnPromotion({ targetX, targetY, color: "w" });
        }
        if (targetX === 7 && pieceType === PieceType.BPawn) {
          isPawnPromotionDetected.current = true;
          setPawnPromotion({ targetX, targetY, color: "b" });
        }
      }

      // Update the board state with the new coordinates
      setBoard((prevBoard) => {
        const updatedPieces = prevBoard.pieces.map((piece) =>
          piece.x === x && piece.y === y
            ? { ...piece, x: targetX, y: targetY }
            : piece.x === targetX && piece.y === targetY
            ? { ...piece, x: targetX, y: targetY, type: pieceType }
            : piece
        );

        let finalObj = {
          ...prevBoard,
          pieces: updatedPieces,
        };

        if (mode === "game") {
          finalObj.playingOrder = board.playingOrder === "w" ? "b" : "w";
        }

        return finalObj;
      });
    };

    const result = [];
    for (let i = 0; i < 8; i++) {
      for (let j = 0; j < 8; j++) {
        const isLightCell = (i + j) % 2 === 0;
        const piece = board.pieces.find((value: Piece) => value.x === i && value.y === j);

        const id = `${numberToChar(j)}${8 - i}`;

        let isPieceDraggableByOrder = true;
        if (mode === "game") {
          if (piece?.type.includes("white") && board.playingOrder === "b") {
            isPieceDraggableByOrder = false;
          }
          if (piece?.type.includes("black") && board.playingOrder === "w") {
            isPieceDraggableByOrder = false;
          }
        }

        const pathIndexes = selectedPath?.split("-");
        const isInThirdLevel = pathIndexes?.length === 3;

        result.push(
          <div
            id={id}
            key={i + "-" + j}
            className={"cell"}
            style={{
              position: "inherit",
              backgroundColor: isLightCell ? boardThemes[backgroundTheme].light : boardThemes[backgroundTheme].dark,
              border: backgroundTheme === "absoluteWhite" ? `${getStrokeWidth() * 0.05}px solid black` : "unset",
            }}
            onClick={() => handleTileClick(i, j)}
            onDragOver={(event) => {
              handleDragOver(event);
            }}
            onDrop={(event) => handleDrop(event, i, j)}
            draggable={false}
            onMouseDown={() => {
              if (isOptionKeyDown && isShiftKeyDown) {
                setTertiaryArrowStart(id);
                return;
              }

              if (isOptionKeyDown) {
                setSecondaryArrowStart(id);
              }
              if (isShiftKeyDown) {
                setPrimaryArrowStart(id);
              }
            }}
            onMouseUp={() => {
              if (isOptionKeyDown && isShiftKeyDown) {
                const arrow = tertiaryArrowStart + id;
                setTertiaryArrows((prevArrows) =>
                  prevArrows.includes(arrow) ? prevArrows.filter((value) => value !== arrow) : [...prevArrows, arrow]
                );
                setTertiaryArrowStart("");

                if (primaryArrows.includes(arrow)) {
                  setPrimaryArrows((prevArrows) => prevArrows.filter((val) => val !== arrow));
                }

                if (secondaryArrows.includes(arrow)) {
                  setSecondaryArrows((prevArrows) => prevArrows.filter((val) => val !== arrow));
                }
                return;
              }

              if (isOptionKeyDown) {
                const arrow = secondaryArrowStart + id;
                setSecondaryArrows((prevArrows) =>
                  prevArrows.includes(arrow) ? prevArrows.filter((value) => value !== arrow) : [...prevArrows, arrow]
                );
                setSecondaryArrowStart("");

                if (primaryArrows.includes(arrow)) {
                  setPrimaryArrows((prevArrows) => prevArrows.filter((val) => val !== arrow));
                }

                if (tertiaryArrows.includes(id)) {
                  setTertiaryArrows((prevArrows) => prevArrows.filter((val) => val !== arrow));
                }
              }

              if (isShiftKeyDown) {
                const arrow = primaryArrowStart + id;
                setPrimaryArrows((prevArrows) =>
                  prevArrows.includes(arrow) ? prevArrows.filter((value) => value !== arrow) : [...prevArrows, arrow]
                );
                setPrimaryArrowStart("");

                if (secondaryArrows.includes(arrow)) {
                  setSecondaryArrows((prevArrows) => prevArrows.filter((val) => val !== arrow));
                }

                if (tertiaryArrows.includes(arrow)) {
                  setTertiaryArrows((prevArrows) => prevArrows.filter((val) => val !== arrow));
                }
              }
            }}
          >
            {piece &&
              (pieceSet === "arabic" ? (
                <span
                  style={{ zIndex: 1, color: piece.type.includes("black") ? "red" : "black", fontWeight: 500, fontSize: "1.2em" }}
                  draggable={
                    currentSelectionState === SelectionState.FreeSelection &&
                    !isOptionKeyDown &&
                    !isShiftKeyDown &&
                    isPieceDraggableByOrder &&
                    !isInThirdLevel
                  }
                  onDragStart={(event) => handleDragStart(event, i, j, piece.type)}
                >
                  {getArabicPiece(piece)}
                </span>
              ) : (
                <img
                  style={{ zIndex: 1 }}
                  src={getPieceImgUrl(i, j)}
                  draggable={
                    currentSelectionState === SelectionState.FreeSelection &&
                    !isOptionKeyDown &&
                    !isShiftKeyDown &&
                    isPieceDraggableByOrder &&
                    !isInThirdLevel
                  }
                  onDragStart={(event) => handleDragStart(event, i, j, piece.type)}
                />
              ))}
            {(secondarySelectedTiles.includes(id) || primarySelectedTiles.includes(id) || tertiarySelectedTiles.includes(id)) && (
              <div
                style={{
                  boxSizing: "border-box",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  border: `${getStrokeWidth() * 0.5}px solid ${
                    secondarySelectedTiles.includes(id)
                      ? localPreferences.secondaryColor
                      : tertiarySelectedTiles.includes(id)
                      ? localPreferences.tertiaryColor
                      : localPreferences.primaryColor
                  }`,
                  zIndex: 1,
                }}
              />
            )}
            {(tertiaryDottedTiles.includes(id) || secondaryDottedTiles.includes(id) || primaryDottedTiles.includes(id)) && (
              <div
                style={{
                  boxSizing: "border-box",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  zIndex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    borderRadius: "50%",
                    background: secondaryDottedTiles.includes(id)
                      ? localPreferences.secondaryColor
                      : tertiaryDottedTiles.includes(id)
                      ? localPreferences.tertiaryColor
                      : localPreferences.primaryColor,
                    width: localPreferences.dotRange ? localPreferences.dotRange + "%" : "50%",
                    height: localPreferences.dotRange ? localPreferences.dotRange + "%" : "50%",
                  }}
                />
              </div>
            )}
          </div>
        );
      }
    }
    return result;
  };

  const renderSelectionBar = (type: "white" | "black") => {
    return (
      <>
        <SelectionBtn
          type={SelectionState.FreeSelection}
          setState={setSelectionState}
          isSelected={currentSelectionState === SelectionState.FreeSelection}
        />
        {Object.values(SelectionState)
          .filter((value) => value.startsWith(type))
          .map((value: SelectionState, index: number) => (
            <SelectionBtn key={index} type={value} setState={setSelectionState} isSelected={currentSelectionState === value} />
          ))}
        <SelectionBtn
          type={SelectionState.FreeRemove}
          setState={setSelectionState}
          isSelected={currentSelectionState === SelectionState.FreeRemove}
        />
      </>
    );
  };

  const handlePlayingOrderChange = (e: SelectChangeEvent<"w" | "b">) => {
    setBoard((prevBoard) => {
      return {
        ...prevBoard,
        playingOrder: e.target.value === "w" ? "w" : "b",
      };
    });
  };

  const handleActionClick = (type: "initial" | "clear") => {
    switch (type) {
      case "initial":
        setCurrentFEN(initialFEN);
        break;
      case "clear":
        setCurrentFEN(emptyBoardFEN);
        break;
    }
  };

  const handleFENChange = (e: any) => {
    setCurrentFEN(e.target.value);
  };

  const handleCastlingChange = (color: "white" | "black", side: "King" | "Queen") => {
    setBoard((prevBoard) => {
      const castlingOptions = { ...prevBoard.castlingOptions };

      if (color === "white") {
        if (side === "King") {
          castlingOptions.whiteKingSide = !castlingOptions.whiteKingSide;
        } else {
          castlingOptions.whiteQueenSide = !castlingOptions.whiteQueenSide;
        }
      } else {
        if (side === "King") {
          castlingOptions.blackKingSide = !castlingOptions.blackKingSide;
        } else {
          castlingOptions.blackQueenSide = !castlingOptions.blackQueenSide;
        }
      }

      return {
        ...prevBoard,
        castlingOptions,
      };
    });
  };

  const getIndex = (index: number) => {
    if (index % 2 === 0) {
      return "";
    }

    return (index + 1) / 2 + ".";
  };

  const getRuleColor = (color: string) => {
    if (color.toLowerCase() === "#ffffff") {
      return "black";
    }

    return color;
  };

  const clearDrawings = () => {
    setSecondarySelectedTiles([]);
    setPrimarySelectedTiles([]);
    setTertiarySelectedTiles([]);

    setSecondaryArrows([]);
    setPrimaryArrows([]);
    setTertiaryArrows([]);

    setTertiaryDottedTiles([]);
    setSecondaryDottedTiles([]);
    setPrimaryDottedTiles([]);
  };

  const getStrokeWidth = () => {
    if (!boardAreaRef.current) return 0;

    return boardAreaRef.current.clientHeight * 0.02;
  };

  const handleExportGame = () => {
    // Export moveList to json file
    const finalObj = {
      gameName: gameExportName,
      result: gameWinner,
      game: gameName,
      gameDesc,
      gameVariant,
      questionNo,
      book,
      pageCell,
      event,
      site,
      date,
      white,
      black,
      eco,
      round,
      timeControl,
      whiteClock,
      blackClock,
      clock,
      moveList,
    };

    localStorage.setItem("gameExport", JSON.stringify(finalObj));

    const a = document.createElement("a");
    const file = new Blob([JSON.stringify(finalObj)], { type: "text/plain" });
    a.href = URL.createObjectURL(file);
    a.download = `${gameExportName}.json`;
    a.click();
  };

  const handleWinnerConfirm = () => {
    setGameEnded(true);
  };

  const handlePromotion = (
    type: "blackQueen" | "whiteQueen" | "blackRook" | "whiteRook" | "blackBishop" | "whiteBishop" | "blackKnight" | "whiteKnight"
  ) => {
    setBoard((prevBoard) => {
      const tempBoard = { ...prevBoard };
      const foundIndex = tempBoard.pieces.findIndex(
        (piece) => piece.x === pawnPromotion.targetX && piece.y === pawnPromotion.targetY
      );
      if (foundIndex !== -1) {
        tempBoard.pieces[foundIndex].type = type as any;
      }

      return tempBoard;
    });
    setPawnPromotion(undefined);
  };

  const handleCastlingClick = (type: "wks" | "wqs" | "bks" | "bqs") => {
    isCastlingClicked.current = true;
    isTurnedBack.current = false;

    setBoard((prevBoard) => {
      const castlingOptions = { ...prevBoard.castlingOptions };
      let pieces = [...prevBoard.pieces];

      switch (type) {
        case "wks":
          castlingOptions.whiteKingSide = !castlingOptions.whiteKingSide;
          pieces = pieces.filter((piece) => {
            if (piece.type === "whiteKing") return false;
            if (piece.type === "whiteRook" && piece.x === 7 && piece.y === 7) return false;
            if (piece.x === 7 && piece.y === 5) return false;
            if (piece.x === 7 && piece.y === 6) return false;
            return piece;
          });

          pieces.push({
            type: PieceType.WRook,
            x: 7,
            y: 5,
          });

          pieces.push({
            type: PieceType.WKing,
            x: 7,
            y: 6,
          });

          lastMove.current = { color: "w", move: "O-O" };
          break;
        case "wqs":
          castlingOptions.whiteQueenSide = !castlingOptions.whiteQueenSide;
          pieces = pieces.filter((piece) => {
            if (piece.type === "whiteKing") return false;
            if (piece.type === "whiteRook" && piece.x === 7 && piece.y === 0) return false;
            if (piece.x === 7 && piece.y === 1) return false;
            if (piece.x === 7 && piece.y === 2) return false;
            if (piece.x === 7 && piece.y === 3) return false;
            return piece;
          });

          pieces.push({
            type: PieceType.WRook,
            x: 7,
            y: 3,
          });

          pieces.push({
            type: PieceType.WKing,
            x: 7,
            y: 2,
          });

          lastMove.current = { color: "w", move: "O-O-O" };
          break;
        case "bks":
          castlingOptions.blackKingSide = !castlingOptions.blackKingSide;
          pieces = pieces.filter((piece) => {
            if (piece.type === "blackKing") return false;
            if (piece.type === "blackRook" && piece.x === 0 && piece.y === 7) return false;
            if (piece.x === 0 && piece.y === 5) return false;
            if (piece.x === 0 && piece.y === 6) return false;
            return piece;
          });

          pieces.push({
            type: PieceType.BRook,
            x: 0,
            y: 5,
          });

          pieces.push({
            type: PieceType.BKing,
            x: 0,
            y: 6,
          });

          lastMove.current = { color: "b", move: "O-O" };
          break;
        case "bqs":
          castlingOptions.blackQueenSide = !castlingOptions.blackQueenSide;
          pieces = pieces.filter((piece) => {
            if (piece.type === "blackKing") return false;
            if (piece.type === "blackRook" && piece.x === 0 && piece.y === 0) return false;
            if (piece.x === 0 && piece.y === 1) return false;
            if (piece.x === 0 && piece.y === 2) return false;
            if (piece.x === 0 && piece.y === 3) return false;
            return piece;
          });

          pieces.push({
            type: PieceType.BRook,
            x: 0,
            y: 3,
          });

          pieces.push({
            type: PieceType.BKing,
            x: 0,
            y: 2,
          });

          lastMove.current = { color: "b", move: "O-O-O" };
          break;
      }

      return {
        ...prevBoard,
        pieces,
        castlingOptions,
      };
    });
  };

  const getDescriptionValue = () => {
    if (!selectedPath) {
      return moveList[moveList.length - 1].description;
    }

    const pathList = selectedPath.split("-").map((val) => parseInt(val));
    if (pathList.length === 1) {
      return moveList[pathList[0]].description;
    } else if (pathList.length === 2) {
      return moveList[pathList[0]].children[pathList[1]].description;
    } else {
      return moveList[pathList[0]].children[pathList[1]].children[pathList[2]].description;
    }
  };

  const handleDeleteVariant = () => {
    const tempMoveList = [...moveList];
    const pathIndexes = selectedPath?.split("-")?.map((val) => parseInt(val));
    let newSelectedPath = "";
    if (pathIndexes?.length === 2) {
      tempMoveList[pathIndexes[0]].children = [];
      newSelectedPath = `${pathIndexes[0]}`;
    } else if (pathIndexes?.length === 3) {
      tempMoveList[pathIndexes[0]].children[pathIndexes[1]].children = [];
      newSelectedPath = `${pathIndexes[0]}-${pathIndexes[1]}`;
    }

    setSelectedPath(newSelectedPath);
    setMoveList(tempMoveList);
  };

  const handlePromoteVariant = () => {
    if (window.confirm("The other variants will be lost. Are you sure to want to continue?")) {
      let tempMoveList: any[] = [];
      const pathIndexes = selectedPath?.split("-")?.map((val) => parseInt(val));
      let newSelectedPath = "";

      if (pathIndexes?.length === 2) {
        const firstMove = {
          ...moveList[pathIndexes[0]],
          move: "initial",
          children: [],
        };
        tempMoveList = [firstMove, ...moveList[pathIndexes[0]].children];
        newSelectedPath = `${pathIndexes[1] + 1}`;
      } else if (pathIndexes?.length === 3) {
        const firstMove = {
          ...moveList[pathIndexes[0]].children[pathIndexes[1]],
          move: "initial",
          children: [],
        };
        tempMoveList = [firstMove, ...moveList[pathIndexes[0]].children[pathIndexes[1]].children];
        newSelectedPath = `${pathIndexes[2] + 1}`;
      }

      setSelectedPath(newSelectedPath);
      setMoveList(tempMoveList);
    }
  };

  return (
    <>
      <div
        className="editor-container"
        // onKeyDown={handleKeyDown}
        // onKeyUp={handleKeyUp}
        tabIndex={0}
        ref={containerDiv}
      >
        <div className="left">
          <div className="left__left" />
          <div className="left__right">
            <div className="board-container">
              {mode === "editor" ? (
                <div className="selection-area">{renderSelectionBar("black")}</div>
              ) : (
                <div className="castling-area">
                  <div>
                    <Button
                      variant="outlined"
                      disabled={!board.castlingOptions.blackQueenSide}
                      onClick={() => handleCastlingClick("bqs")}
                    >
                      long castling
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      disabled={!board.castlingOptions.blackKingSide}
                      onClick={() => handleCastlingClick("bks")}
                    >
                      short castling
                    </Button>
                  </div>
                </div>
              )}
              <div
                className="board-area"
                ref={boardAreaRef}
                style={{ borderWidth: backgroundTheme === "absoluteWhite" ? getStrokeWidth() * 0.1 : getStrokeWidth() * 0.05 }}
              >
                {renderBoard()}
                <div className="vertical-ruler" style={{ width: getStrokeWidth() + "px", fontSize: getStrokeWidth() + "px" }}>
                  {new Array(8).fill("").map((val, index) => {
                    return (
                      <span
                        className="rule"
                        style={{
                          color:
                            index % 2 === 0
                              ? getRuleColor(boardThemes[backgroundTheme].light)
                              : getRuleColor(boardThemes[backgroundTheme].dark),
                        }}
                      >
                        {8 - index}
                      </span>
                    );
                  })}
                </div>
                <div
                  className="horizontal-ruler"
                  style={{
                    height: getStrokeWidth() + "px",
                    fontSize: getStrokeWidth() + "px",
                    bottom: getStrokeWidth() / 3 + "px",
                  }}
                >
                  {new Array(8).fill("").map((val, index) => {
                    return (
                      <span
                        className="rule"
                        style={{
                          color:
                            index % 2 === 0
                              ? getRuleColor(boardThemes[backgroundTheme].light)
                              : getRuleColor(boardThemes[backgroundTheme].dark),
                        }}
                      >
                        {String.fromCharCode(65 + index)}
                      </span>
                    );
                  })}
                </div>
                {primaryArrows.map((arrow, index) => (
                  <ChessArrow
                    key={"primary-" + index}
                    start={arrow.slice(0, 2)}
                    end={arrow.slice(-2)}
                    color={localPreferences.primaryColor}
                    strokeWidth={getStrokeWidth()}
                  />
                ))}
                {secondaryArrows.map((arrow, index) => (
                  <ChessArrow
                    key={"secondary-" + index}
                    start={arrow.slice(0, 2)}
                    end={arrow.slice(-2)}
                    color={localPreferences.secondaryColor}
                    strokeWidth={getStrokeWidth()}
                  />
                ))}
                {tertiaryArrows.map((arrow, index) => (
                  <ChessArrow
                    key={"tertiary-" + index}
                    start={arrow.slice(0, 2)}
                    end={arrow.slice(-2)}
                    color={localPreferences.tertiaryColor}
                    strokeWidth={getStrokeWidth()}
                  />
                ))}
              </div>
              {mode === "editor" ? (
                <div className={"selection-area"}>{renderSelectionBar("white")}</div>
              ) : (
                <div className="castling-area">
                  <div>
                    <Button
                      variant="outlined"
                      disabled={!board.castlingOptions.whiteQueenSide}
                      onClick={() => handleCastlingClick("wqs")}
                    >
                      long castling
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      disabled={!board.castlingOptions.whiteKingSide}
                      onClick={() => handleCastlingClick("wks")}
                    >
                      short castling
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div className="input-container">
              <TextField
                value={currentFEN}
                label="FEN"
                fullWidth
                autoComplete="off"
                error={!validateFEN(currentFEN)}
                onChange={handleFENChange}
                disabled={mode === "game"}
              />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="menu-container">
            {mode === "editor" ? (
              <>
                <div className="game-info-card">
                  <Select id="playing-order" value={board.playingOrder} fullWidth onChange={handlePlayingOrderChange}>
                    <MenuItem value={"w"}>White to play</MenuItem>
                    <MenuItem value={"b"}>Black to play</MenuItem>
                  </Select>
                  <div className="castling">
                    <div className="title">Castling</div>
                    <div className="castling-option">
                      <div className="label">White</div>
                      <div className="action">
                        <FormGroup
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-around",
                            flex: 1,
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={board.castlingOptions.whiteKingSide}
                                onChange={() => handleCastlingChange("white", "King")}
                              />
                            }
                            label="O-O"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={board.castlingOptions.whiteQueenSide}
                                onChange={() => handleCastlingChange("white", "Queen")}
                              />
                            }
                            label="O-O-O"
                          />
                        </FormGroup>
                      </div>
                    </div>

                    <div className="castling-option">
                      <div className="label">Black</div>
                      <div className="action">
                        <FormGroup
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-around",
                            flex: 1,
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={board.castlingOptions.blackKingSide}
                                onChange={() => handleCastlingChange("black", "King")}
                              />
                            }
                            label="O-O"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={board.castlingOptions.blackQueenSide}
                                onChange={() => handleCastlingChange("black", "Queen")}
                              />
                            }
                            label="O-O-O"
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="en-passant">
                    <div className="label">En passant</div>
                    <Select fullWidth labelId="demo-simple-select-label" id="demo-simple-select">
                      <MenuItem value={"yes"}>yes</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className="select-game-card">
                  <Select
                    fullWidth
                    value={selectedGame}
                    onChange={(e) => setSelectedGame(e.target.value)}
                    className="game-type-select"
                  >
                    <MenuItem value={"-1"}>Set the board</MenuItem>
                    <span style={{ fontSize: 12, marginLeft: 10, color: "#aaa" }}>Popular Opennings</span>
                    {preSettedGames.opennings.map((game, index) => {
                      return (
                        <MenuItem key={index} value={game.FEN}>
                          {game.label}
                        </MenuItem>
                      );
                    })}
                    <span style={{ fontSize: 12, marginLeft: 10, color: "#aaa" }}>Endgame Positions</span>
                    {preSettedGames.endGames.map((game, index) => {
                      return (
                        <MenuItem key={index} value={game.FEN}>
                          {game.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </>
            ) : (
              <>
                <div className="movement-container">
                  <div className="title">Move List</div>
                  <div className="list">
                    {moveList.map((move: any, firstIndex: number) => (
                      <>
                        <div
                          className="item"
                          style={{
                            flexBasis: firstIndex === 0 ? "100%" : "45%",
                          }}
                        >
                          <div
                            style={{
                              cursor: "pointer",
                              background: selectedPath === firstIndex.toString() ? "#ffffffaa" : "unset",
                            }}
                            onClick={() => {
                              if (selectedPath === firstIndex.toString()) return;

                              if (firstIndex + 1 === moveList.length) {
                                setSelectedPath(undefined);
                              } else {
                                setSelectedPath(firstIndex + "");
                              }
                              isTurnedBack.current = true;
                              setCurrentFEN(move.FEN);
                            }}
                          >
                            {firstIndex > 0 && getIndex(firstIndex)}
                            {move?.move}
                          </div>
                          {move?.children?.length > 0 && (
                            <div style={{ display: "flex", flexDirection: "column", fontSize: "1em", gap: "5px" }}>
                              {move.children.map((firstChildMove: any, secondIndex: number) => {
                                const pathList = selectedPath?.split("-").map((val) => parseInt(val));
                                let isSelected = false;
                                if (pathList?.length === 2 && pathList[0] === firstIndex && pathList[1] === secondIndex) {
                                  isSelected = true;
                                }

                                return (
                                  <div>
                                    <span
                                      onClick={() => {
                                        if (isSelected) return;

                                        setSelectedPath(firstIndex + "-" + secondIndex);
                                        isTurnedBack.current = true;
                                        setCurrentFEN(firstChildMove.FEN);
                                      }}
                                      style={{
                                        marginLeft: "5px",
                                        cursor: "pointer",
                                        background: isSelected ? "#ffffffaa" : "unset",
                                      }}
                                    >
                                      {secondIndex === 0 ? "↳" : <>&nbsp;&nbsp;&nbsp;</>} {secondIndex + 1}.{firstChildMove.move}
                                    </span>
                                    {firstChildMove.children?.length > 0 && (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          fontSize: "1em",
                                          gap: "5px",
                                          marginLeft: "20px",
                                        }}
                                      >
                                        {firstChildMove.children.map((secondChildMove: any, thirdIndex: number) => {
                                          const pathIndexes = selectedPath?.split("-").map((path) => parseInt(path));
                                          let isThirdSelected = false;
                                          if (pathIndexes?.length === 3) {
                                            if (
                                              pathIndexes[0] === firstIndex &&
                                              pathIndexes[1] === secondIndex &&
                                              pathIndexes[2] === thirdIndex
                                            ) {
                                              isThirdSelected = true;
                                            }
                                          }

                                          return (
                                            <div
                                              style={{ cursor: "pointer", background: isThirdSelected ? "#ffffffaa" : "unset" }}
                                              onClick={() => {
                                                setSelectedPath(`${firstIndex}-${secondIndex}-${thirdIndex}`);
                                              }}
                                            >
                                              {thirdIndex === 0 ? "↳" : <>&nbsp;&nbsp;&nbsp;</>}
                                              {thirdIndex + 1}.{secondChildMove.move}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </>
                    ))}
                    {moveList.length === 1 && (
                      <div className="item" style={{ flexBasis: "45%" }}>
                        1.
                      </div>
                    )}
                  </div>
                </div>
                {(selectedPath?.split("-").length === 2 || selectedPath?.split("-").length === 3) && (
                  <div className="flex flex-row gap-3 items-center">
                    <Button variant="outlined" onClick={handlePromoteVariant}>
                      Promote Variant
                    </Button>
                    <Button variant="outlined" onClick={handleDeleteVariant}>
                      Delete Variant
                    </Button>
                  </div>
                )}
                <div className="flex flex-row gap-3 items-center">
                  <TextField
                    value={getDescriptionValue()}
                    placeholder="Move Description"
                    fullWidth
                    onChange={(e) => {
                      const tempMoveList = [...moveList];

                      if (!selectedPath) {
                        tempMoveList[moveList.length - 1].description = e.target.value;
                      } else {
                        const pathList = selectedPath.split("-").map((val) => parseInt(val));
                        if (pathList.length === 1) {
                          tempMoveList[pathList[0]].description = e.target.value;
                        } else if (pathList.length === 2) {
                          tempMoveList[pathList[0]].children[pathList[1]].description = e.target.value;
                        } else {
                          tempMoveList[pathList[0]].children[pathList[1]].children[pathList[2]].description = e.target.value;
                        }
                      }

                      setMoveList(tempMoveList);
                    }}
                  />
                </div>
                {isGameEnded && <div>Game Ended, winner: {gameWinner}</div>}
                <div className="flex flex-row gap-3 items-center">
                  <div> Set Result</div>
                  <div>
                    <Select value={gameWinner} onChange={(e) => setGameWinner(e.target.value)}>
                      <MenuItem value={"1/2-1/2"}>1/2-1/2</MenuItem>
                      <MenuItem value={"1-0"}>1-0</MenuItem>
                      <MenuItem value={"0-1"}>0-1</MenuItem>
                    </Select>
                  </div>
                  <div>
                    <IconButton disabled={!Boolean(gameWinner)} onClick={handleWinnerConfirm}>
                      <CheckIcon />
                    </IconButton>
                  </div>
                </div>
              </>
            )}
            <div className="action-btn-list">
              {mode === "editor" ? (
                <>
                  <DrawingInfoDisplayer />
                  <Button
                    startIcon={<RestartAltIcon />}
                    style={{ justifyContent: "flex-start" }}
                    onClick={() => handleActionClick("initial")}
                  >
                    Starting Position
                  </Button>
                  <Button
                    startIcon={<DeleteForeverIcon />}
                    style={{ justifyContent: "flex-start" }}
                    onClick={() => handleActionClick("clear")}
                  >
                    Clear Board
                  </Button>
                  <Button startIcon={<RestartAltIcon />} style={{ justifyContent: "flex-start" }} onClick={() => clearDrawings()}>
                    Clear Drawings
                  </Button>
                  <Button
                    startIcon={<SlideshowIcon />}
                    style={{ justifyContent: "flex-start" }}
                    onClick={() => {
                      setSelectionState(SelectionState.FreeSelection);
                      setMoveList([{ move: "initial", FEN: currentFEN, children: [], description: "" }]);
                      toggleMode();
                    }}
                  >
                    Continue From Here
                  </Button>
                  <Button
                    startIcon={<GetAppIcon />}
                    style={{ justifyContent: "flex-start" }}
                    onClick={() => setExportModalOpen(true)}
                  >
                    Export Board
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    startIcon={<RestartAltIcon />}
                    style={{ justifyContent: "flex-start" }}
                    onClick={() => {
                      setSelectedPath(undefined);
                      setGameWinner(undefined);
                      setGameEnded(false);
                      setMoveList([]);
                      setMode("editor");
                      setBoard((prevBoard) => {
                        return {
                          ...prevBoard,
                          castlingOptions: {
                            whiteKingSide: true,
                            whiteQueenSide: true,
                            blackKingSide: true,
                            blackQueenSide: true,
                          },
                        };
                      });
                    }}
                  >
                    Return to editor
                  </Button>
                  <Button
                    startIcon={<GetAppIcon />}
                    style={{ justifyContent: "flex-start" }}
                    onClick={() => {
                      let exportGame: any = localStorage.getItem("gameExport");
                      if (exportGame) {
                        exportGame = JSON.parse(exportGame);
                        if (exportGame.gameName) setGameExportName(exportGame.gameName);
                        if (exportGame.game) setGameName(exportGame.game);
                        if (exportGame.gameVariant) setGameVariant(exportGame.gameVariant);
                        if (exportGame.questionNo) setQuestionNo(exportGame.questionNo);
                        if (exportGame.book) setBook(exportGame.book);
                        if (exportGame.pageCell) setPageCell(exportGame.pageCell);
                        if (exportGame.event) setEvent(exportGame.event);
                        if (exportGame.site) setSite(exportGame.site);
                        if (exportGame.date) setDate(exportGame.date);
                        if (exportGame.white) setWhite(exportGame.white);
                        if (exportGame.black) setBlack(exportGame.black);
                        if (exportGame.eco) setEco(exportGame.eco);
                        if (exportGame.round) setRound(exportGame.round);
                        if (exportGame.timeControl) setTimeControl(exportGame.timeControl);
                        if (exportGame.whiteClock) setWhiteClock(exportGame.whiteClock);
                        if (exportGame.blackClock) setBlackClock(exportGame.blackClock);
                        if (exportGame.clock) setClock(exportGame.clock);
                      }

                      setGameExportOpen(true);
                    }}
                  >
                    Export Game
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal open={isExportModalOpen} onClose={handleClose}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" color="whitesmoke">
            Export Type
          </Typography>
          <div className="mt-3 flex flex-row gap-3">
            <Button variant="outlined" onClick={() => handleExport("svg")}>
              SVG
            </Button>
            <Button variant="outlined" onClick={() => handleExport("jpg")}>
              JPG
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal open={isGameExportOpen} onClose={() => setGameExportOpen(false)}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" color="whitesmoke">
            Export Game
          </Typography>
          <div style={{ maxHeight: "40vh", overflow: "auto" }}>
            <div className="mt-3 flex flex-row gap-3">
              <TextField
                value={gameExportName}
                onChange={(e) => setGameExportName(e.target.value)}
                placeholder="Game Name*"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Enter the name of the game">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mt-3 flex flex-row gap-3">
              <Select value={gameName} fullWidth onChange={(e) => setGameName(e.target.value)}>
                <MenuItem value={"chess"}>Chess</MenuItem>
                <MenuItem value={"Checkers"}>Checkers</MenuItem>
              </Select>
            </div>
            <div className="mt-3 flex flex-row gap-3">
              <TextField
                value={gameDesc}
                onChange={(e) => setGameDesc(e.target.value)}
                fullWidth
                placeholder="Game Description"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Enter the game description">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mt-3 flex flex-row gap-3">
              <Select value={gameVariant} fullWidth onChange={(e) => setGameVariant(e.target.value)}>
                <MenuItem value={"standard"}>Standard</MenuItem>
                <MenuItem value={"shatranj"}>Shatranj</MenuItem>
                <MenuItem value={"chaturanga"}>Chaturanga</MenuItem>
              </Select>
            </div>
            <div className="mt-3 flex flex-row gap-3">
              <TextField
                value={questionNo}
                onChange={(e) => setQuestionNo(e.target.value)}
                placeholder="Question No"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Enter the question number">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mt-3 flex flex-row gap-3">
              <TextField
                value={book}
                onChange={(e) => setBook(e.target.value)}
                placeholder="Book"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Enter the name of the book">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mt-3 flex flex-row gap-3">
              <TextField
                value={pageCell}
                onChange={(e) => setPageCell(e.target.value)}
                placeholder="PageCell"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Enter the page cell from the book">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mt-3 flex flex-row gap-3">
              <TextField
                value={event}
                onChange={(e) => setEvent(e.target.value)}
                placeholder="Event"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Enter the name of the game">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mt-3 flex flex-row gap-3">
              <TextField
                value={site}
                onChange={(e) => setSite(e.target.value)}
                placeholder="Site"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Enter the name of the site">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mt-3 flex flex-row gap-3">
              <TextField
                value={date}
                onChange={(e) => setDate(e.target.value)}
                placeholder="Date"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="2024.04.08">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mt-3 flex flex-row gap-3">
              <TextField
                value={white}
                onChange={(e) => setWhite(e.target.value)}
                placeholder="White"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Beliavsky, A ">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mt-3 flex flex-row gap-3">
              <TextField
                value={black}
                onChange={(e) => setBlack(e.target.value)}
                placeholder="Black"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Timman, J ">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mt-3 flex flex-row gap-3">
              <TextField
                value={eco}
                onChange={(e) => setEco(e.target.value)}
                placeholder="ECO"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="E35">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mt-3 flex flex-row gap-3">
              <TextField
                value={round}
                onChange={(e) => setRound(e.target.value)}
                placeholder="Round"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="2 ">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mt-3 flex flex-row gap-3">
              <TextField
                value={timeControl}
                onChange={(e) => setTimeControl(e.target.value)}
                placeholder="TimeControl"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="40/7200:3600 ">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mt-3 flex flex-row gap-3">
              <TextField
                value={whiteClock}
                onChange={(e) => setWhiteClock(e.target.value)}
                placeholder="WhiteClock"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="2:00:00">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mt-3 flex flex-row gap-3">
              <TextField
                value={blackClock}
                onChange={(e) => setBlackClock(e.target.value)}
                placeholder="BlackClock"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="2:00:00">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mt-3 flex flex-row gap-3">
              <TextField
                value={clock}
                onChange={(e) => setClock(e.target.value)}
                placeholder="Clock"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="W/1:34:56">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="mt-3 flex flex-row justify-end">
            <Button onClick={handleExportGame} variant="outlined" disabled={gameExportName.length === 0}>
              Export
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal open={Boolean(pawnPromotion)}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" color="whitesmoke">
            Pawn Promotion
          </Typography>
          <div className="mt-3 flex flex-row gap-3">
            <div
              style={{
                background: "white",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handlePromotion(`${pawnPromotion?.color === "w" ? "white" : "black"}Queen`)}
            >
              {<img src={`/assets/images/pieceSets/${pieceSet}/${pawnPromotion?.color}Q.svg`} width={50} height={50} />}
            </div>
            <div
              style={{
                background: "white",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handlePromotion(`${pawnPromotion.color === "w" ? "white" : "black"}Knight`)}
            >
              {<img src={`/assets/images/pieceSets/${pieceSet}/${pawnPromotion?.color}N.svg`} width={50} height={50} />}
            </div>
            <div
              style={{
                background: "white",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handlePromotion(`${pawnPromotion.color === "w" ? "white" : "black"}Bishop`)}
            >
              {<img src={`/assets/images/pieceSets/${pieceSet}/${pawnPromotion?.color}B.svg`} width={50} height={50} />}
            </div>
            <div
              style={{
                background: "white",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handlePromotion(`${pawnPromotion.color === "w" ? "white" : "black"}Rook`)}
            >
              {<img src={`/assets/images/pieceSets/${pieceSet}/${pawnPromotion?.color}R.svg`} width={50} height={50} />}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Editor;
