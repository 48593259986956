import React, { useState, useEffect } from "react";
import Xarrow from "react-xarrows";
import "./scss/chessArrow.scss";

interface IChessArrow {
  start: string;
  end: string;
  color: string;
  strokeWidth: number;
}

const ChessArrow: React.FC<IChessArrow> = ({ start, end, color, strokeWidth }) => {
  return (
    <span className="arrow-container">
      <Xarrow
        divContainerStyle={{ pointerEvents: "none" }}
        zIndex={2}
        start={start}
        end={end}
        startAnchor={"middle"}
        endAnchor={"middle"}
        color={color}
        curveness={0}
        strokeWidth={strokeWidth}
        headSize={3}
      />
    </span>
  );
};

export default ChessArrow;
