import AnalysisPage from "../pages/Analysis";
import { RouteType } from "./routeType";
import LandingPage from "../pages/Landing/landingPage";
import Management from "../pages/Tournament/management";
import Editor from "../pages/Editor/Editor";
import EditorWithDynamicSize from "../pages/Editor/EditorDynamicSize";
import HomeworkGenerator from "../pages/HomeworkGenerator/HomeworkGenerator";
import path from "path";
import SavedGames from "../pages/SavedGames/SavedGames";

enum RoutePaths {
  LandingPage = "/",
  AnalysisPage = "/analysis",
  EditorPage = "/editor",
  EditorPageWithFen = "/editor/:defaultFen",
  EditorWithDynamicSizePage = "/editor-with-dynamic-size",
  TournamentManagement = "/tournament/management",
  HomeworkGenerator = "/hwgenerator",
  SavedGames = "/saved-games",
}

const routes: RouteType[] = [
  {
    path: RoutePaths.LandingPage,
    element: <LandingPage />,
  },
  {
    path: RoutePaths.TournamentManagement,
    element: <Management />,
  },
  {
    path: RoutePaths.EditorPage,
    element: <Editor />,
  },
  {
    path: RoutePaths.EditorPageWithFen,
    element: <Editor />,
  },
  {
    path: RoutePaths.EditorWithDynamicSizePage,
    element: <EditorWithDynamicSize />,
  },
  {
    path: RoutePaths.AnalysisPage,
    element: <AnalysisPage />,
  },
  {
    path: RoutePaths.HomeworkGenerator,
    element: <HomeworkGenerator />,
  },
  {
    path: RoutePaths.SavedGames,
    element: <SavedGames />,
  },
];

export { RoutePaths as routePaths, routes };
