export interface Board {
  pieces: Piece[];
  playingOrder: "w" | "b";
  castlingOptions: {
    whiteKingSide: boolean;
    whiteQueenSide: boolean;
    blackKingSide: boolean;
    blackQueenSide: boolean;
  };
}

export interface Piece {
  x: number;
  y: number;
  type: PieceType;
}

export enum PieceType {
  BKing = "blackKing",
  BQueen = "blackQueen",
  BRook = "blackRook",
  BBishop = "blackBishop",
  BKnight = "blackKnight",
  BPawn = "blackPawn",
  WKing = "whiteKing",
  WQueen = "whiteQueen",
  WRook = "whiteRook",
  WBishop = "whiteBishop",
  WKnight = "whiteKnight",
  WPawn = "whitePawn",
}
