import Referee from "../../components/Chess/components/Referee/Referee";

const AnalysisPage = () => {
  return (
    <div>
      <Referee />
    </div>
  );
};

export default AnalysisPage;
