// boardSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./types";
import { boardThemes, pieceSets } from "../constants";

interface BoardState {
  pieceSet: string;
  backgroundTheme: string;
  analysisPreferences: {
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    dotRange: number;
  };
}

const initialState: BoardState = {
  pieceSet: pieceSets[0],
  backgroundTheme: Object.keys(boardThemes)[0],
  analysisPreferences: {
    primaryColor: "#ff0000",
    secondaryColor: "#00ff00",
    tertiaryColor: "#ffff00",
    dotRange: 50,
  },
};

const boardSlice = createSlice({
  name: "board",
  initialState,
  reducers: {
    setPieceSet: (state, action: PayloadAction<string>) => {
      state.pieceSet = action.payload;
    },
    setBackgroundTheme: (state, action: PayloadAction<string>) => {
      state.backgroundTheme = action.payload;
    },
    setAnalysisPreferences: (
      state,
      action: PayloadAction<{
        primaryColor: string;
        secondaryColor: string;
        tertiaryColor: string;
        dotRange: number;
      }>
    ) => {
      state.analysisPreferences = action.payload;
    },
  },
});

export const { setPieceSet, setBackgroundTheme, setAnalysisPreferences } = boardSlice.actions;
export const selectPieceSet = (state: RootState) => state.board.pieceSet;
export const selectBackgroundTheme = (state: RootState) => state.board.backgroundTheme;
export const selectAnalysisPreferences = (state: RootState) => state.board.analysisPreferences;

export default boardSlice.reducer;
