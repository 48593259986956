export const preSettedGames = {
  opennings: [
    {
      label: `B02 Alekhine Defense`,
      FEN: "rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 1",
    },
    {
      label: `B04 Alekhine Defense: Modern Variation`,
      FEN: "rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R b KQkq - 0 1",
    },
    {
      label: `C23 Elephant's Opening`,
      FEN: "rnbqkbnr/pppp1ppp/8/4p3/2B1P3/8/PPPP1PPP/RNBQK1NR b KQkq - 0 1",
    },
    {
      label: `B10 Caro-Kann Defense`,
      FEN: "rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 1",
    },
  ],
  endGames: [
    {
      label: `Vizier vs Rook`,
      FEN: "8/8/4r3/3k4/8/8/3K1Q2/8 w - - 0 1",
    },
    {
      label: `Pawns Ending XXX`,
      FEN: "8/8/3k4/1p2p3/1P2K3/8/7P/8 w - - 0 1",
    },
    {
      label: `Pawns Ending XXIX`,
      FEN: "8/8/2p1kPp1/6P1/4K3/8/8/8 w - - 0 1",
    },
    {
      label: `Pawns Ending XXVIII`,
      FEN: "8/8/8/p3kPp1/6P1/4K3/8/8 w - - 0 1",
    },
  ],
};

export const initialFEN = "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1";

export const emptyBoardFEN = "8/8/8/8/8/8/8/8 w - - 0 1";
